import styled from '@emotion/styled';
import { Title } from 'components/benefits/components/beneffits.components';
import breakpoints from 'styles/_breakpoints';
import colors from 'styles/_colors';

import { ReactComponent as MinusIcon } from '../../../assets/images/faq-section/minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/faq-section/plus.svg';

export const FaqTitle = styled(Title)`
  font-size: 36px;
  line-height: 56px;

  @media (min-width: ${breakpoints.desktop}) {
    font-size: 56px;
  }
`;

export const FaqImage = styled.img`
  display: none;

  @media (min-width: ${breakpoints.tablet}) {
    display: block;
    object-fit: cover;
    width: 340px;
    height: 483px;
    position: sticky;
    top: 160px;
  }
  @media (min-width: ${breakpoints.desktop}) {
    width: 495.18px;
    height: 704px;
    position: sticky;
    top: 160px;
  }
`;

export const FaqList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
  }
  & .title-desktop-container {
    width: 100%;
    display: flex;
  }

  & .title-desktop {
    display: none;

    @media (min-width: ${breakpoints.desktop}) {
      display: block;
    }
  }
`;

export const OpenIcon = styled(PlusIcon)`
  @media (min-width: ${breakpoints.tablet}) {
    width: 32px;
    height: 32px;
  }
`;

export const CloseIcon = styled(MinusIcon)`
  @media (min-width: ${breakpoints.tablet}) {
    width: 32px;
    height: 32px;
  }
`;

export const FaqContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
  border-bottom: ${({ isOpen }) => (isOpen ? 'none' : `1px solid ${colors.applaudoNavyblue}`)};

  & .faq-question {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }

  & .faq-answer {
    margin: 0;
    padding: 1rem 2rem 2rem 2rem;
  }

  @media (hover: hover) {
    :hover {
      & svg {
        & circle {
          fill: ${colors.applaudoRed};
        }

        & path {
          fill: ${colors.white};
          stroke: ${colors.white};
        }
      }
    }
  }
`;
