import ApiService from 'api/abstract/ApiService';
import { TPWithType } from 'utils/types/tp-program.type';

class JobService extends ApiService<TPWithType, undefined> {
  constructor() {
    super(process.env.REACT_APP_BACKEND_API as string, 'jobs');
  }
}

export default JobService;
