import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import city from './slice/city';
import country from './slice/country';
import form from './slice/form';
import jobs from './slice/jobs';

export const store = configureStore({
  reducer: {
    form,
    country,
    city,
    jobs,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
