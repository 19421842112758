import React from 'react';

import { BurgerButton } from './link-button';

type ToggleButtonProps = {
  onClick: () => void;
};

const ToggleButton = (props: ToggleButtonProps) => {
  const { onClick } = props;
  return (
    <BurgerButton type="button" onClick={onClick}>
      <div className="toggle-button-line" />
      <div className="toggle-button-line" />
      <div className="toggle-button-line" />
    </BurgerButton>
  );
};

export default ToggleButton;
