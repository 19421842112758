import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import Button from 'components/button/Button';
import ClappChip from 'components/clapp-chip/ClappChip';
import DragAndDrop from 'components/drag-and-drop/DragAndDrop';
import DraggableItem from 'components/draggable-item/DraggableItem';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { thunkStoreCandidate } from 'store/actions/form.action';
import { thunkGetJobs } from 'store/actions/jobs.action';
import { DraggableItemTypes } from 'utils/constants/draggable-item-types';
import { updateItemIndex } from 'utils/functions/arrayHelpers';
import { useAppSelector } from 'utils/hooks/useAppSelector';
import { TraineeProgramType } from 'utils/types/tp-program.type';

import { SnackbarState } from '../../../utils/types/snackbar-state.type';
import {
  SSJobsContainer,
  SecondSectionContainer,
  TermsAndConditions,
} from './form-section.components';
import TermsAndConditionsDialog from './terms-and-conditions';

const SecondSection = () => {
  const [candidateFile, setCandidateFile] = useState<File | null>();
  const [selectedTP, setSelectedTP] = useState<TraineeProgramType[]>([]);
  const [open, setOpen] = useState(false);
  const jobs = useAppSelector((data) => data.jobs.value);
  const { hasError, error } = useAppSelector((data) => data.form.value);
  const formState = useAppSelector((data) => data.form.value);
  const dispatch = useDispatch();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    open: false,
    type: 'error',
    title: '',
    message: '',
  });

  const handleTPChange = useCallback(
    ({ label, slug, SRId }: TraineeProgramType) => {
      const job = selectedTP.find((tp) => tp.slug === slug);
      if (!job && selectedTP.length < 3) {
        setSelectedTP((selectedTPs) => [...selectedTPs, { label, slug, SRId }]);
      } else {
        setSelectedTP(selectedTP.filter((tp) => tp.slug !== slug));
      }
    },
    [selectedTP]
  );

  useEffect(() => {
    if (hasError && error !== '') {
      setSnackbarState({
        open: true,
        title: 'An error occurred',
        message: error,
        type: 'error',
      });
    }
  }, [hasError, error]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
  };

  const storeTrainee = useCallback(() => {
    dispatch(
      thunkStoreCandidate({ file: candidateFile as File, tp: selectedTP.map(({ slug }) => slug) })
    );
  }, [dispatch, candidateFile, selectedTP]);

  const handleReorderPrograms = (dragIndex: number, dropIndex: number) => {
    setSelectedTP(updateItemIndex<TraineeProgramType>(selectedTP, dragIndex, dropIndex));
  };

  useEffect(() => {
    dispatch(thunkGetJobs());
  }, [dispatch]);

  return (
    <SecondSectionContainer>
      <SSJobsContainer>
        <div className="description">
          You can select up to 3 programs,{' '}
          <b>the first one selected will be the option of primary interest</b> from the ones you can
          choose.
        </div>
        <div className={`select-count ${selectedTP.length === 3 ? 'limit' : ''}`}>
          {selectedTP.length} of 3
        </div>
        <div className="categories-container">
          {!jobs.loading &&
            jobs.jobs.map((jobType) => (
              <div key={jobType.code}>
                <div className="tp-name">{jobType.name}</div>
                <div className="tp-container">
                  {jobType.jobs.map((job) => (
                    <ClappChip
                      key={job.slug}
                      onClick={() => handleTPChange(job)}
                      label={job.label}
                      isActive={!!selectedTP.find(({ slug }) => slug === job.slug)}
                    />
                  ))}
                </div>
              </div>
            ))}
          <div className="selected-tps-container">
            <div className="selected-tps-container__title">Organize your selected programs</div>
            <div className="selected-tps-container__description">
              Ensure that your selected options are ordered from 1-3, where 1 is the most desired
              and 3 is the least desired option. Re-organize them by{' '}
              <b>dragging and dropping in that order.</b>
            </div>
            <div className="selected-tps-container__items">
              {selectedTP.map(({ label, slug }, tpItemIndex) => (
                <div key={`${label}-${slug}`} className="selected-tps-container__item">
                  <span className="item-position">{tpItemIndex + 1}</span>
                  <DraggableItem
                    draggableType={DraggableItemTypes.TECH_ITEM}
                    id={`${slug}-${tpItemIndex}`}
                    index={tpItemIndex}
                    onPosChange={handleReorderPrograms}
                  >
                    <ClappChip label={label} isActive />
                  </DraggableItem>
                </div>
              ))}
            </div>
          </div>
        </div>
      </SSJobsContainer>
      <DragAndDrop setCandidateFile={setCandidateFile} />
      <TermsAndConditions>
        By clicking the &quot;Register&quot; button you agree to Applaudo&apos;s&nbsp;
        <span aria-hidden="true" onClick={() => setOpen(true)}>
          Terms and Conditions
        </span>
      </TermsAndConditions>
      <div className="button-container">
        <Button
          disabled={!candidateFile || !(selectedTP.length > 0) || formState.loading}
          className="full-width"
          onClick={storeTrainee}
          buttonType="primary"
        >
          {formState.loading ? <CircularProgress size="20px" /> : 'Register'}
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ textAlign: 'center' }}>
          <span style={{ fontSize: '28px', fontWeight: 700 }}>Terms and Conditions</span>
          <CloseIcon
            aria-label="close"
            onClick={handleClose}
            style={{ cursor: 'pointer' }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          />
        </DialogTitle>
        <DialogContent>
          <TermsAndConditionsDialog handleClose={handleClose} />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={7000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarState.type} sx={{ width: '100%' }}>
          {snackbarState.title}
          <br />
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SecondSectionContainer>
  );
};

export default SecondSection;
