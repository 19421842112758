const colors = Object.freeze({
  applaudoRed: '#ff4040',
  applaudoNavyblue: '#011625',
  applaudoNavyblue8: '#92bef7',
  applaudoLightblue: '#417ba4',
  applaudoLightGray: '#f1f1f1',
  applaudoPurple: '#893eff',
  applaudoPurple110: '#b58ff0',
  applaudoPurple90: '#6e2fcf',
  applaudoGray: '#F7F7F7',
  applaudoYellow: '#FCB314',
  applaudoGreen: '#1BCB80',
  yellow: '#f7ba40',
  lightYellow: 'rgba(247, 186, 64, 0.2)',
  black: '#000',
  gainsboro: '#dfdfdf',
  white: '#ffffff',
  mediumGray: '#c4c4c4',
  survaGray: '#8f8f8f',
  cyan: '#0bcffc',
  lightGreen: '#1bde8a',
  brown: '#856404',
  brownGray: '#c4c4c4',
  lightGray: '#f6f6f6',
  quickSilver: '#9f9f9f',
  redHoverFocused: '#ff6060',
  redPressed: '#e60000',
  grayBorder: '#e2e2e2',
  errorRed: '#b80000',
  charcoal: '#36434e',
  coolGray: '#687177',
  platinum: '#e2e2e2',
  success: '#1bd180',
  warning: '#f9bb4e',
});

export default colors;
