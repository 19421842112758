import { ReactComponent as CheckIcon } from 'assets/images/finish.svg';
import { ReactComponent as UserIcon } from 'assets/images/first-step.svg';
import { ReactComponent as SecondStepIcon } from 'assets/images/second-step.svg';
import React, { useEffect, useState } from 'react';
import colors from 'styles/_colors';
import { useAppSelector } from 'utils/hooks/useAppSelector';

import CircularProgress from '../../circular-progress/CircularProgress';
import LineProgress from '../../line-progress/LineProgress';

export const FormProgressBar = () => {
  const [firstStep, setFirstStep] = useState(false);
  const [secondStep, setSecondStep] = useState(false);
  const [finished, setFinished] = useState(false);
  const formState = useAppSelector((state) => state.form.value.state);

  useEffect(() => {
    if (formState) {
      if (formState === 1) {
        setFirstStep(true);
      }
      if (formState === 2) {
        setSecondStep(true);
        setFinished(true);
      }
    }
  }, [formState]);

  return (
    <>
      <div className="form-header__progress__circle">
        <CircularProgress
          color={colors.applaudoGreen}
          size={30}
          strokeWidth={2}
          percentage={firstStep ? 100 : 0}
        >
          <UserIcon fill={firstStep ? colors.applaudoGreen : colors.mediumGray} x="30%" y="20%" />
        </CircularProgress>
      </div>
      <div className="form-header__progress__line">
        <LineProgress
          color={colors.applaudoGreen}
          firstSize={100}
          size={100}
          strokeWidth={4}
          percentage={firstStep ? 100 : 0}
        />
      </div>
      <div className="form-header__progress__circle">
        <CircularProgress
          color={colors.applaudoGreen}
          size={30}
          strokeWidth={2}
          percentage={secondStep ? 100 : 0}
        >
          <SecondStepIcon
            fill={secondStep ? colors.applaudoGreen : colors.mediumGray}
            x="25%"
            y="25%"
          />
        </CircularProgress>
      </div>
      <div className="form-header__progress__line">
        <LineProgress
          color={colors.applaudoGreen}
          firstSize={100}
          size={100}
          strokeWidth={4}
          percentage={secondStep ? 100 : 0}
        />
      </div>
      <div className="form-header__progress__circle">
        <CircularProgress
          color={colors.applaudoGreen}
          size={30}
          strokeWidth={2}
          percentage={finished ? 100 : 0}
        >
          <CheckIcon fill={finished ? colors.applaudoGreen : colors.mediumGray} x="30%" y="30%" />
        </CircularProgress>
      </div>
    </>
  );
};

export default FormProgressBar;
