import styled from 'styled-components';

import colors from '../../styles/_colors';

type ButtonProps = {
  buttonType: 'outlined' | 'outlined-dark' | 'outlined-red' | 'outlined-gray' | 'primary' | 'dark';
  color?: string;
};

const Button = styled.button<ButtonProps>`
  font-family: inherit;
  background: ${(props: ButtonProps) => {
    switch (props.buttonType) {
      case 'dark':
        return colors.applaudoNavyblue;
      case 'outlined-dark':
      case 'outlined-red':
      case 'outlined':
      case 'outlined-gray':
        return 'transparent';
      case 'primary':
        return colors.applaudoRed;
      default:
        return colors.applaudoRed;
    }
  }};
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  cursor: pointer;
  color: ${(props: ButtonProps) => {
    switch (props.buttonType) {
      case 'dark':
        return props.color ?? colors.white;
      case 'outlined-dark':
      case 'outlined':
      case 'outlined-gray':
        return props.color ?? colors.applaudoNavyblue;
      case 'outlined-red':
        return colors.applaudoRed;
      case 'primary':
        return props.color ?? colors.white;
      default:
        return props.color ?? colors.white;
    }
  }};
  padding: 8px;
  min-width: 144px;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props: ButtonProps) => {
    switch (props.buttonType) {
      case 'dark':
        return colors.applaudoNavyblue;
      case 'outlined-dark':
      case 'outlined':
        return colors.applaudoNavyblue;
      case 'outlined-gray':
        return colors.platinum;
      case 'primary':
        return colors.applaudoRed;
      default:
        return colors.applaudoRed;
    }
  }};

  &:hover {
    background: ${(props: ButtonProps) => {
      switch (props.buttonType) {
        case 'dark':
          return colors.applaudoRed;
        case 'outlined-dark':
          return colors.applaudoNavyblue;
        case 'outlined-gray':
          return 'transparent';
        case 'outlined':
          return colors.applaudoRed;
        case 'primary':
          return 'rgb(255, 96, 96);';
        default:
          return colors.applaudoNavyblue;
      }
    }};
    border-color: ${(props: ButtonProps) => {
      switch (props.buttonType) {
        case 'dark':
          return colors.white;
        case 'outlined-dark':
          return colors.applaudoNavyblue;
        case 'outlined':
        case 'outlined-gray':
          return colors.applaudoRed;
        case 'primary':
          return colors.applaudoRed;
        default:
          return colors.applaudoNavyblue;
      }
    }};

    color: ${(props: ButtonProps) => {
      switch (props.buttonType) {
        case 'outlined-gray':
          return colors.applaudoRed;
        default:
          return colors.white;
      }
    }};
    transition: all 0.3s ease 0s;
  }

  &:disabled {
    color: ${colors.charcoal};
    background: ${colors.lightGray};
    border-color: ${colors.lightGray};
  }

  &.full-width {
    padding: 12px !important;
  }
  transition: all 0.3s ease 0s;
`;

export default Button;
