import styled from 'styled-components';
import breakpoints from 'styles/_breakpoints';
import colors from 'styles/_colors';

import { ReactComponent as ApplaudoSvg } from '../../../assets/images/applaudo-brand.svg';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: ${breakpoints.maxWidth};
  margin: 0 auto;
  height: 156px;

  @media (min-width: ${breakpoints.desktop}) {
    justify-content: space-between;
  }

  & .applaudo-brand-container {
    display: none;

    & p {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: ${colors.white};
    }

    @media (min-width: ${breakpoints.tablet}) {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  & .social-media-container {
    display: flex;
    flex-direction: column;

    gap: 20px;

    @media (min-width: ${breakpoints.desktop}) {
      flex-direction: row;
    }

    & .social-media-links {
      display: flex;
      justify-content: space-around;
      @media (min-width: ${breakpoints.desktop}) {
        gap: 1rem;
      }
    }
  }
`;

export const ApplaudoBrand = styled(ApplaudoSvg)`
  & .letter {
    fill: ${colors.white};
  }
`;

export const FooterTitle = styled.h5`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.75em;
  text-transform: uppercase;
  color: ${colors.applaudoRed};
  margin: 0;
`;
