import React from 'react';

import { ProcessContainer, ProcessTitle } from './recruiting-process.components';

type ProcessProps = {
  imgSrc: string;
  title: string;
  description: string;
  isInReverse: boolean;
};
const Process = ({ imgSrc, title, description, isInReverse }: ProcessProps) => (
  <ProcessContainer isInReverse={isInReverse}>
    <img src={imgSrc} alt="technical-test" />
    <div>
      <ProcessTitle>{title}</ProcessTitle>
      <p>{description}</p>
    </div>
  </ProcessContainer>
);

export default Process;
