import { Link } from 'components/red-section/components/red-section.components';
import React from 'react';

import { SideDrawerMenu } from './disapeer-top-bar';
import { LinkButton } from './link-button';

type SideDrawerType = {
  show: boolean;
  hideDrawer: () => void;
};

const SideDrawer = (props: SideDrawerType) => {
  const { show, hideDrawer } = props;
  return (
    <SideDrawerMenu className={show ? 'open' : ''}>
      <ul>
        <li>
          <Link href="#apply-now" onClick={hideDrawer} style={{ display: 'none' }}>
            <LinkButton className="bold" buttonType="outlined">
              Apply Now
              <div className="reddiv" />
            </LinkButton>
          </Link>
        </li>
        <li>
          <Link href="#our-tps" onClick={hideDrawer}>
            <LinkButton className="bold" buttonType="outlined">
              Trainee Program
              <div className="reddiv" />
            </LinkButton>
          </Link>
        </li>
        <li>
          <Link href="#fqs" onClick={hideDrawer}>
            <LinkButton className="bold" buttonType="outlined">
              FAQ&apos;s
              <div className="reddiv" />
            </LinkButton>
          </Link>
        </li>
      </ul>
    </SideDrawerMenu>
  );
};

export default SideDrawer;
