import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import queryParams from 'utils/api/query-params';
import { QueryParamsTypes } from 'utils/types/query-params.type';

import defaultAxiosConfig from '../config/defaultAxiosConfig';

abstract class ApiService<T, N> {
  axios: AxiosInstance;

  uri: string;

  protected constructor(baseUrl: string, uri: string) {
    this.uri = uri;
    this.axios = axios.create({
      ...defaultAxiosConfig(baseUrl),
    });
  }

  async get(params?: QueryParamsTypes): Promise<AxiosResponse<T[] | N> | AxiosError> {
    try {
      const stringParams = params ? queryParams(params) : '';
      return await this.axios.get(`${this.uri}${stringParams}`);
    } catch (error) {
      return error as AxiosError;
    }
  }

  async post<K, M>(params: K): Promise<AxiosResponse<K | M> | AxiosError> {
    try {
      return await this.axios.post(this.uri, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      return error as AxiosError;
    }
  }

  async put<K, M>(params: K): Promise<AxiosResponse<K | M> | AxiosError> {
    try {
      return await this.axios.put(this.uri, params);
    } catch (error) {
      return error as AxiosError;
    }
  }
}

export default ApiService;
