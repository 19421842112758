import styled from 'styled-components';

type DraggableProps = {
  opacity: number;
};

export const Draggable = styled.div<DraggableProps>`
  display: inline-flex;
  opacity: ${(props: DraggableProps) => props.opacity};

  > *:hover {
    cursor: move;
  }
`;
