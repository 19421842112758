import { Link } from 'components/red-section/components/red-section.components';
import React from 'react';

import ApplaudoBrand from '../../assets/images/applaudo-brand.svg';
import { BrandContainer, DisapeerTopBar } from './components/disapeer-top-bar';
import { LinkButton, LinkButtonConstainer } from './components/link-button';
import ToggleButton from './components/toggle-button';

type TopBarProps = {
  toggleClickHandler: () => void;
};

const TopBar = (props: TopBarProps) => {
  const { toggleClickHandler } = props;

  return (
    <DisapeerTopBar>
      <BrandContainer>
        <Link href="https://applaudostudios.com" className="margin_0" target="_blank">
          <img src={ApplaudoBrand} alt="" />
        </Link>
      </BrandContainer>
      <LinkButtonConstainer>
        <Link href="#our-tps">
          <LinkButton buttonType="outlined">
            Trainee Program
            <div className="reddiv" />
          </LinkButton>
        </Link>
        <Link href="#fqs">
          <LinkButton buttonType="outlined">
            FAQ&apos;s
            <div className="reddiv" />
          </LinkButton>
        </Link>

        <Link href="#apply-now" style={{ display: 'none', marginRight: 0 }}>
          <LinkButton buttonType="primary">
            Apply now
            <div className="reddiv" />
          </LinkButton>
        </Link>
      </LinkButtonConstainer>
      <ToggleButton onClick={toggleClickHandler} />
    </DisapeerTopBar>
  );
};

export default TopBar;
