import React, { ReactNode } from 'react';
import { usePreview } from 'react-dnd-multi-backend';

type MobileDragPreviewType = {
  isDragging: boolean;
  children: ReactNode | null;
};

const MobileDragPreview = ({ children, isDragging }: MobileDragPreviewType) => {
  const preview = usePreview();
  if (!preview.display || !isDragging) {
    return null;
  }
  return <div style={{ ...preview.style, opacity: 1 }}>{children}</div>;
};

export default MobileDragPreview;
