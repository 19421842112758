import { ICombinedFaq, IListFaq, INormalFaq } from 'interfaces/faq.interfaces';
import { isListFaq, isNormalFaq } from 'interfaces/type-guards/faq.type-guard';
import React from 'react';

type AnswerProps = {
  faq: INormalFaq | IListFaq | ICombinedFaq;
};

const Answer = ({ faq }: AnswerProps) => {
  if (isNormalFaq(faq)) {
    return (
      <p style={{ position: 'relative' }} className="faq-answer">
        {faq.answer}
      </p>
    );
  }

  if (isListFaq(faq)) {
    return (
      <ul style={{ position: 'relative' }} className="faq-answer">
        {faq.answer.map((element) => (
          <li key={element}>{element}</li>
        ))}
      </ul>
    );
  }

  return (
    <div style={{ position: 'relative' }} className="faq-answer">
      {}
      <p>{faq.answer}</p>
      <ul>
        {faq.requirements.map((requirement) => (
          <li key={requirement}>{requirement}</li>
        ))}
      </ul>
    </div>
  );
};

export default Answer;
