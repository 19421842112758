import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import CandidateService from 'api/services/CandidateService';
import { STORE_CANDIDATE } from 'store/actions-types/form.action-type';
import {
  IFirstSection,
  IFormFetchSection,
  IFormSection,
  IFormState,
  IFormValues,
  ILatLong,
  ISecondSection,
} from 'utils/interfaces/form.interface';
import { DocumentType } from 'utils/types/document.type';

type State = {
  value: IFormSection &
    IFormState & { loading: boolean; verifyCandidate: IFormFetchSection; isOpenSeason: boolean };
};

export const saveFormStateAction = (state: State, action: PayloadAction<number>) => {
  state.value.state = action.payload;
};

export const saveOpenSeasonAction = (state: State, action: PayloadAction<boolean>) => {
  state.value.isOpenSeason = action.payload;
};

export const saveFirstSectionAction = (state: State, action: PayloadAction<IFormValues>) => {
  const { city, country, countryCode, name, lastName, email, phone, documentType, documentId } =
    action.payload;
  state.value.firstSection.location.city = city;
  state.value.firstSection.location.country = country;
  state.value.firstSection.location.countryCode = countryCode;
  state.value.firstSection.firstName = name;
  state.value.firstSection.lastName = lastName;
  state.value.firstSection.email = email;
  state.value.firstSection.phone = phone;
  state.value.firstSection.documentId.type = documentType as DocumentType;
  state.value.firstSection.documentId.value = documentId;
};

export const saveSecontSectionAction = (state: State, action: PayloadAction<ISecondSection>) => {
  state.value.secondSection.file = action.payload.file;
  state.value.secondSection.tp = action.payload.tp;
};

export const saveLocationAction = (state: State, action: PayloadAction<ILatLong>) => {
  state.value.firstSection.location.latitude = action.payload.latitude;
  state.value.firstSection.location.longitude = action.payload.longitude;
};

export const saveSourceAction = (state: State, action: PayloadAction<string>) => {
  state.value.firstSection.candidateSource = action.payload;
};

export const verifyCandidateStartAction = (state: State) => {
  state.value.verifyCandidate.loading = true;
};

export const verifyCandidateSuccessAction = (state: State) => {
  state.value.verifyCandidate.loading = false;
  state.value.verifyCandidate.hasError = false;
  state.value.verifyCandidate.error = '';
};

export const verifyCandidateFailureAction = (state: State, action: PayloadAction<string>) => {
  state.value.verifyCandidate.loading = false;
  state.value.verifyCandidate.hasError = true;
  state.value.verifyCandidate.error = action.payload;
};

export const thunkStoreCandidate = createAsyncThunk(
  STORE_CANDIDATE,
  async (secondSection: ISecondSection, thunkApi) => {
    const candidateService = new CandidateService();
    const state = thunkApi.getState() as { form: State };
    const firstSectionValue = { ...state.form.value.firstSection } as IFirstSection;
    const choices = secondSection.tp;
    const data = { candidate: { ...firstSectionValue, choices }, file: secondSection.file };

    const formData = new FormData();
    formData.append('candidate', JSON.stringify(data.candidate));
    formData.append('file', data.file as File);
    const response = await candidateService.post(formData);

    if ('data' in response) {
      return response;
    }
    throw response;
  }
);
