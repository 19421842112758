import SuccessSVG from 'assets/images/success.svg';
import { Rectangle } from 'components/blue-rectangle/Rectangle';
import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import colors from 'styles/_colors';
import { useFadeAnimation } from 'utils/hooks/useFadeAnimation';

import { SSContentSection, SSImageSection } from './succes-section.components';

const SuccessSectionWrapper = animated(
  styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1fr;
  `
);

const SuccessSection = () => {
  const { elementRef, style } = useFadeAnimation();
  return (
    <Rectangle color={colors.white}>
      <SuccessSectionWrapper ref={elementRef} style={style}>
        <SSImageSection>
          <div className="image">
            <img src={SuccessSVG} alt="Success" />
          </div>
        </SSImageSection>
        <SSContentSection>
          <div className="title">
            Thank you for applying <br /> <small>to our Trainee Program!</small>
          </div>
          <div className="subtitle-container">
            <div className="subtitle-container__email">
              Expect a follow-up soon by email from our team to initiate the process.
            </div>
            <div className="subtitle-container__subtitle">
              If you have any questions, please email us at:{' '}
              <span>traineeprogram@applaudostudios.com</span> and we will assist you as soon as we
              can.
            </div>
          </div>
        </SSContentSection>
      </SuccessSectionWrapper>
    </Rectangle>
  );
};

export default SuccessSection;
