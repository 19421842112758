import { Alert, CircularProgress, Snackbar, TextField } from '@mui/material';
import ApplicantService from 'api/services/ApplicantsService';
import CandidateService from 'api/services/CandidateService';
import { AxiosError } from 'axios';
import { Rectangle } from 'components/blue-rectangle/Rectangle';
import Button from 'components/button/Button';
import React, { useCallback, useState } from 'react';
import colors from 'styles/_colors';
import { handleAxiosError } from 'utils/functions/handleError';
import { ApplicantType } from 'utils/types/applicant.type';

import { SnackbarState } from '../../../utils/types/snackbar-state.type';
import { AlreadyRegisteredContainer } from './form-section.components';

type RequestNewAttemptComponentProps = {
  handleClose: () => void;
  isOpenSeason?: boolean;
} & typeof defaultProps;

const defaultProps = Object.freeze({ isOpenSeason: true });

const RequestNewAttemptComponent = ({
  handleClose,
  isOpenSeason,
}: RequestNewAttemptComponentProps) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    open: false,
    type: 'success',
    title: '',
    message: '',
  });
  const handleRequestNewAttempt = useCallback(async () => {
    const applicantService = new ApplicantService();
    const candidateService = new CandidateService();
    const param: ApplicantType = {
      applicant: {
        email,
      },
    };
    setIsLoading(true);
    try {
      const response = await applicantService.put(param);
      await candidateService.verifyCandidate(param.applicant.email);
      const errorResponse = handleAxiosError(response);
      if (errorResponse) {
        const { error } = errorResponse;
        const { message, title, details } = error;
        if (details.length > 0) {
          const { description } = details[0];
          setSnackbarState({
            open: true,
            message: description,
            title,
            type: 'error',
          });
        } else {
          setSnackbarState({
            open: true,
            message,
            title,
            type: 'error',
          });
        }
      } else {
        setSnackbarState({
          open: true,
          type: 'success',
          message: 'Please check your email to continue with the process',
          title: 'New attempt requested successfully!',
        });
        setEmail('');
        setTimeout(() => handleClose(), 2000);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.isAxiosError) {
        const { response } = axiosError;
        const responseStatus = response?.status;

        if (responseStatus === 409) {
          setSnackbarState({
            open: true,
            message:
              'You cannot request a new attempt, because you are still in the review phase. Expect a follow-up soon from our team to provide you with technical tests.',
            title: 'Error',
            type: 'error',
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [email, handleClose]);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <Rectangle color={colors.white}>
      <AlreadyRegisteredContainer>
        {isOpenSeason ? (
          <p>
            If you&apos;d like to request a new attempt for the test, please enter your email below
            and we will contact you soon.
          </p>
        ) : (
          <p>
            Unfortunately at this moment, we don&apos;t have available programs. However, if you
            request a new attempt, we will consider your application when the programs are opened
            again in the future.
          </p>
        )}
        <div className="form-container">
          <TextField
            label="*Email"
            autoComplete="off"
            type="email"
            variant="standard"
            onChange={(input) => setEmail(input.target.value)}
          />
        </div>
        <div className="form-action">
          <Button buttonType="outlined-gray" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={isLoading} buttonType="primary" onClick={handleRequestNewAttempt}>
            {isLoading ? <CircularProgress size="20px" /> : 'Submit'}
          </Button>
        </div>
      </AlreadyRegisteredContainer>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarState.type} sx={{ width: '100%' }}>
          {snackbarState.title}
          <br />
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </Rectangle>
  );
};

RequestNewAttemptComponent.defaultProps = defaultProps;
export default RequestNewAttemptComponent;
