import { createSlice } from '@reduxjs/toolkit';
import { thunkGetJobs } from 'store/actions/jobs.action';
import { TPWithType } from 'utils/types/tp-program.type';

type InitialStateType = {
  loading: boolean;
  jobs: TPWithType[];
};

const initialState: InitialStateType = {
  loading: false,
  jobs: [],
};

export const jobsSlice = createSlice({
  name: 'form',
  initialState: {
    value: initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkGetJobs.fulfilled, (state, action) => {
      if (action.payload) state.value.jobs = action.payload;
      else state.value.jobs = [];
      state.value.loading = false;
    });
    builder.addCase(thunkGetJobs.pending, (state) => {
      state.value.loading = true;
    });
    builder.addCase(thunkGetJobs.rejected, (state) => {
      state.value.loading = false;
      state.value.jobs = [];
    });
  },
});

export default jobsSlice.reducer;
