import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Title } from 'components/benefits/components/beneffits.components';
import { Rectangle } from 'components/blue-rectangle/Rectangle';
import React, { useState } from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { useFadeAnimation } from 'utils/hooks/useFadeAnimation';

import colors from '../../styles/_colors';
import { useWindowSize } from '../../utils/hooks/useWindowSize';
import ProgramController from './components/program-controller';
import {
  ProgramOptions,
  ProgramOptionsList,
  ProgramsWrapper,
  TPProgramContent,
  TPProgramSelectionWrapper,
} from './components/tp-programs.components';

const TPProgamsWrapper = animated(styled.div`
  padding: 60px 32px 80px 32px;
  position: relative;
`);

const TPPrograms = () => {
  const [selectedProgram, setSelectedProgram] = useState(1);
  const isMobile = useWindowSize();
  const { elementRef, style } = useFadeAnimation();
  return (
    <Rectangle id="our-tps" color={colors.white}>
      <TPProgamsWrapper ref={elementRef} style={style}>
        <Title>Our Trainee Programs</Title>
        <TPProgramContent>
          {!isMobile && (
            <TPProgramSelectionWrapper>
              <ProgramOptionsList>
                <ProgramOptions
                  className={selectedProgram === 0 ? 'selected' : ''}
                  onClick={() => setSelectedProgram(0)}
                >
                  All
                  <div className="reddiv" />
                </ProgramOptions>
                <ProgramOptions
                  className={selectedProgram === 1 ? 'selected' : ''}
                  onClick={() => setSelectedProgram(1)}
                >
                  Web Development
                  <div className="reddiv" />
                </ProgramOptions>
                <ProgramOptions
                  className={selectedProgram === 2 ? 'selected' : ''}
                  onClick={() => setSelectedProgram(2)}
                >
                  DevOps
                  <div className="reddiv" />
                </ProgramOptions>
                <ProgramOptions
                  className={selectedProgram === 3 ? 'selected' : ''}
                  onClick={() => setSelectedProgram(3)}
                >
                  Data
                  <div className="reddiv" />
                </ProgramOptions>
                <ProgramOptions
                  className={selectedProgram === 4 ? 'selected' : ''}
                  onClick={() => setSelectedProgram(4)}
                >
                  Mobile Development
                  <div className="reddiv" />
                </ProgramOptions>
                <ProgramOptions
                  className={selectedProgram === 5 ? 'selected' : ''}
                  onClick={() => setSelectedProgram(5)}
                >
                  UX/UI Design
                  <div className="reddiv" />
                </ProgramOptions>
                <ProgramOptions
                  className={selectedProgram === 6 ? 'selected' : ''}
                  onClick={() => setSelectedProgram(6)}
                >
                  QA
                  <div className="reddiv" />
                </ProgramOptions>
              </ProgramOptionsList>
            </TPProgramSelectionWrapper>
          )}
          {isMobile && (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">Select trainee program</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(event) => setSelectedProgram(event.target.value as number)}
                label="Select trainee program"
                value={selectedProgram}
              >
                <MenuItem value={1}>Web Development</MenuItem>
                <MenuItem value={2}>DevOps</MenuItem>
                <MenuItem value={3}>Data</MenuItem>
                <MenuItem value={4}>Mobile Development</MenuItem>
                <MenuItem value={5}>UI/UX Design</MenuItem>
                <MenuItem value={6}>QA</MenuItem>
              </Select>
            </FormControl>
          )}
          <ProgramsWrapper>
            <ProgramController selectedProgram={selectedProgram} />
          </ProgramsWrapper>
        </TPProgramContent>
      </TPProgamsWrapper>
    </Rectangle>
  );
};

export default TPPrograms;
