import { ICombinedFaq, IListFaq, INormalFaq } from 'interfaces/faq.interfaces';

export function isNormalFaq(faq: INormalFaq | IListFaq | ICombinedFaq): faq is INormalFaq {
  return (
    typeof (faq as INormalFaq).answer === 'string' && (faq as INormalFaq).requirements === undefined
  );
}

export function isListFaq(faq: INormalFaq | IListFaq | ICombinedFaq): faq is IListFaq {
  return Array.isArray((faq as IListFaq).answer) && (faq as IListFaq).requirements === undefined;
}
