import styled from 'styled-components';

import colors from '../../../styles/_colors';

type ImageSectionType = {
  imgSrc: string;
};

export const ImageSection = styled.div<ImageSectionType>`
  height: 640px;
  width: 100%;
  background-image: url(${(props) => props.imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (min-width: 300px) {
    background-size: cover 192px;
    width: 100%;
    height: 192px;
  }

  @media (min-width: 650px) {
    background-size: cover;
    height: 597px;
    width: 100%;
  }

  @media (min-width: 651px) {
    height: 667px;
  }

  @media (min-width: 680px) {
    height: 643px;
  }

  @media (min-width: 715px) {
    height: 619px;
  }

  @media (min-width: 760px) {
    height: 650px;
  }

  @media (min-width: 850px) {
    height: 627px;
  }

  @media (min-width: 880px) {
    height: 603px;
  }

  @media (min-width: 1000px) {
    height: 579px;
    width: 110%;
  }
`;

export const ContentSection = styled.div`
  height: auto;
  padding: 80px 32px;

  @media (min-width: 300px) {
    padding: 80px 32px;
  }

  @media (min-width: 650px) {
    width: 100%;
    padding: 80px 32px;
  }
`;

export const ContentTitle = styled.h1`
  margin: 0 0 5px 0;
  font-size: 3.5em;
  line-height: 2.5rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-weight: 650;
  font-style: normal;
  color: ${colors.applaudoNavyblue};

  position: relative;

  & span {
    font-weight: 400;
  }

  & svg {
    margin: -25px 8px 0 0;
    left: 0;
  }
  @media (min-width: 300px) {
    font-size: 2em;
    line-height: 1.5rem;
  }
  @media (min-width: 500px) {
    justify-content: start;
  }

`;

export const ContentSubtitle = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 24px;
  color: ${colors.coolGray};
  margin: 16px 0;
`;

export const ContentDescription = styled.div`
  margin: 16px 0 0;
  max-width: 496px;
  align-items: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const P = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;

  & span {
    font-weight: 600;
  }

  @media (min-width: 300px) {
    margin: 24px 0;
  }

  @media (min-width: 760px) {
    margin: 32px 0;
  }
`;
