import React from 'react';

import AndroidIcon from '../../../assets/images/android.svg';
import AngularIcon from '../../../assets/images/angular.svg';
import AWS from '../../../assets/images/aws.svg';
import BigDataDevelopment from '../../../assets/images/big-data.svg';
import DataEngineer from '../../../assets/images/data-engineer.svg';
import DevopsDevelopment from '../../../assets/images/devops-development.svg';
import GCP from '../../../assets/images/gcp.svg';
import iOSIcon from '../../../assets/images/ios.svg';
import Java from '../../../assets/images/java.svg';
import MobileDevelopment from '../../../assets/images/mobile-development.svg';
import DotNet from '../../../assets/images/net.svg';
import NodeIcon from '../../../assets/images/node.svg';
import PHP from '../../../assets/images/php.svg';
import PythonIcon from '../../../assets/images/python.svg';
import QAFunctional from '../../../assets/images/qa-functional.svg';
import QA from '../../../assets/images/qa.svg';
import ReactNative from '../../../assets/images/react-native.svg';
import ReactIcon from '../../../assets/images/react.svg';
import Ruby from '../../../assets/images/ruby.svg';
import Scala from '../../../assets/images/scala.svg';
import UIIcon from '../../../assets/images/ui.svg';
import UxUiDesign from '../../../assets/images/uxui.svg';
import WebDevelopment from '../../../assets/images/web-development.svg';
import WPIcon from '../../../assets/images/wordpress.svg';
import { useWindowSize } from '../../../utils/hooks/useWindowSize';
import Button from '../../button/Button';
import { Link } from '../../red-section/components/red-section.components';
import {
  Program,
  ProgramDescriptionBody,
  ProgramDescriptionContainer,
  ProgramDescriptionFooter,
  ProgramDescriptionTechs,
  ProgramDescriptionTitle,
  ProgramImage,
  ProgramImageContainer,
} from './tp-programs.components';

type ProgramControllerType = {
  selectedProgram: number;
};

const ProgramController: React.FC<ProgramControllerType> = (props) => {
  const { selectedProgram } = props;
  const isMobile = useWindowSize();

  return (
    <>
      {(selectedProgram === 1 || selectedProgram === 0) && (
        <Program className={selectedProgram === 0 ? 'all' : ''}>
          <ProgramImageContainer>
            <ProgramImage src={WebDevelopment} />
          </ProgramImageContainer>
          <ProgramDescriptionContainer>
            <ProgramDescriptionTitle>Web Development</ProgramDescriptionTitle>
            <ProgramDescriptionBody>
              <div className="qualifications">
                <p>Qualifications</p>
              </div>
              <ul className="qs-list">
                <li>Object oriented programming knowledge.</li>
                <li>Knowledge of a programming language.</li>
                <li>Advanced English level.</li>
              </ul>
            </ProgramDescriptionBody>
            <ProgramDescriptionTechs>
              <div className="title">Available for</div>
              <div className="techs-container">
                <div className="techs_container__tech">
                  <img src={ReactIcon} alt="" />
                  <div className="tech-name">React</div>
                </div>
                <div className="techs_container__tech">
                  <img src={PythonIcon} alt="" />
                  <div className="tech-name">Python</div>
                </div>
                <div className="techs_container__tech">
                  <img src={NodeIcon} alt="" />
                  <div className="tech-name">Node.js</div>
                </div>
                <div className="techs_container__tech">
                  <img src={AngularIcon} alt="" />
                  <div className="tech-name">Angular</div>
                </div>
                <div className="techs_container__tech">
                  <img src={WPIcon} alt="" />
                  <div className="tech-name">Wordpress</div>
                </div>
                <div className="techs_container__tech">
                  <img src={Java} alt="" />
                  <div className="tech-name">Java</div>
                </div>
                <div className="techs_container__tech">
                  <img src={PHP} alt="" />
                  <div className="tech-name">PHP Backend</div>
                </div>
                <div className="techs_container__tech">
                  <img src={Ruby} alt="" />
                  <div className="tech-name">Ruby on Rails</div>
                </div>
                <div className="techs_container__tech">
                  <img src={DotNet} alt="" />
                  <div className="tech-name">.NET</div>
                </div>
              </div>
            </ProgramDescriptionTechs>
            <ProgramDescriptionFooter>
              <Link href="#apply-now">
                <Button buttonType={!isMobile ? 'primary' : 'outlined-red'}>Apply now</Button>
              </Link>
            </ProgramDescriptionFooter>
          </ProgramDescriptionContainer>
        </Program>
      )}
      {(selectedProgram === 2 || selectedProgram === 0) && (
        <Program className={selectedProgram === 0 ? 'all' : ''}>
          <ProgramImageContainer>
            <ProgramImage src={DevopsDevelopment} />
          </ProgramImageContainer>
          <ProgramDescriptionContainer>
            <ProgramDescriptionTitle>DevOps</ProgramDescriptionTitle>
            <ProgramDescriptionBody>
              <div className="qualifications">
                <p>Qualifications</p>
              </div>
              <ul className="qs-list">
                <li>Knowledge of Networking.</li>
                <li>Basic knowledge of Linux (Terminal).</li>
                <li>Knowledge of Docker Containers.</li>
                <li>Advanced English level.</li>
              </ul>
            </ProgramDescriptionBody>
            <ProgramDescriptionTechs>
              <div className="title">Available for</div>
              <div className="techs-container">
                <div className="techs_container__tech">
                  <img src={AWS} alt="" />
                  <div className="tech-name">AWS</div>
                </div>
                <div className="techs_container__tech">
                  <img src={GCP} alt="" />
                  <div className="tech-name">GCP</div>
                </div>
              </div>
            </ProgramDescriptionTechs>
            <ProgramDescriptionFooter>
              <Link href="#apply-now">
                <Button buttonType={!isMobile ? 'primary' : 'outlined-red'}>Apply now</Button>
              </Link>
            </ProgramDescriptionFooter>
          </ProgramDescriptionContainer>
        </Program>
      )}
      {(selectedProgram === 3 || selectedProgram === 0) && (
        <Program className={selectedProgram === 0 ? 'all' : ''}>
          <ProgramImageContainer>
            <ProgramImage src={BigDataDevelopment} />
          </ProgramImageContainer>
          <ProgramDescriptionContainer>
            <ProgramDescriptionTitle>Big Data</ProgramDescriptionTitle>
            <ProgramDescriptionBody>
              <div className="qualifications">
                <p>Qualifications</p>
              </div>
              <ul className="qs-list">
                <li>Knowledge of programming language.</li>
                <li>Good knowledge bases of Object oriented programming.</li>
                <li>Good knowledge bases of database design.</li>
                <li>Advanced English level.</li>
              </ul>
            </ProgramDescriptionBody>
            <ProgramDescriptionTechs>
              <div className="title">Available for</div>
              <div className="techs-container">
                <div className="techs_container__tech">
                  <img src={Scala} alt="" />
                  <div className="tech-name">Scala</div>
                </div>
              </div>
            </ProgramDescriptionTechs>
            <ProgramDescriptionFooter>
              <Link href="#apply-now">
                <Button buttonType={!isMobile ? 'primary' : 'outlined-red'}>Apply now</Button>
              </Link>
            </ProgramDescriptionFooter>
          </ProgramDescriptionContainer>
        </Program>
      )}
      {(selectedProgram === 3 || selectedProgram === 0) && (
        <Program className={selectedProgram === 0 ? 'all' : 'not-first'}>
          <ProgramImageContainer>
            <ProgramImage src={DataEngineer} />
          </ProgramImageContainer>
          <ProgramDescriptionContainer>
            <ProgramDescriptionTitle>Data Engineer</ProgramDescriptionTitle>
            <ProgramDescriptionBody>
              <div className="qualifications">
                <p>Qualifications</p>
              </div>
              <ul className="qs-list">
                <li>Good knowledge of databases.</li>
                <li>Knowledge of data processing.</li>
                <li>Good analytical thinking.</li>
                <li>Advanced English level.</li>
              </ul>
            </ProgramDescriptionBody>
            <ProgramDescriptionFooter>
              <Link href="#apply-now">
                <Button buttonType={!isMobile ? 'primary' : 'outlined-red'}>Apply now</Button>
              </Link>
            </ProgramDescriptionFooter>
          </ProgramDescriptionContainer>
        </Program>
      )}
      {(selectedProgram === 4 || selectedProgram === 0) && (
        <Program className={selectedProgram === 0 ? 'all' : ''}>
          <ProgramImageContainer>
            <ProgramImage src={MobileDevelopment} />
          </ProgramImageContainer>
          <ProgramDescriptionContainer>
            <ProgramDescriptionTitle>Mobile Development</ProgramDescriptionTitle>
            <ProgramDescriptionBody>
              <div className="qualifications">
                <p>Qualifications</p>
              </div>
              <ul className="qs-list">
                <li>Object oriented programming knowledge.</li>
                <li>Knowledge of a programming language.</li>
                <li>Advanced English level.</li>
              </ul>
            </ProgramDescriptionBody>
            <ProgramDescriptionTechs>
              <div className="title">Available for</div>
              <div className="techs-container">
                <div className="techs_container__tech">
                  <img src={AndroidIcon} alt="" />
                  <div className="tech-name">Android</div>
                </div>
                <div className="techs_container__tech">
                  <img src={iOSIcon} alt="" />
                  <div className="tech-name">iOS</div>
                </div>
                <div className="techs_container__tech">
                  <img src={ReactNative} alt="" />
                  <div className="tech-name">React Native</div>
                </div>
              </div>
            </ProgramDescriptionTechs>
            <ProgramDescriptionFooter>
              <Link href="#apply-now">
                <Button buttonType={!isMobile ? 'primary' : 'outlined-red'}>Apply now</Button>
              </Link>
            </ProgramDescriptionFooter>
          </ProgramDescriptionContainer>
        </Program>
      )}
      {(selectedProgram === 5 || selectedProgram === 0) && (
        <Program className={selectedProgram === 0 ? 'all' : ''}>
          <ProgramImageContainer>
            <ProgramImage src={UxUiDesign} />
          </ProgramImageContainer>
          <ProgramDescriptionContainer>
            <ProgramDescriptionTitle>UX/UI Design</ProgramDescriptionTitle>
            <ProgramDescriptionBody>
              <div className="qualifications">
                <p>Qualifications</p>
              </div>
              <ul className="qs-list">
                <li>Excellent Storytelling skills</li>
                <li>Up-to-date with the latest design trends, techniques and technologies.</li>
                <li>Basic understanding of Design Thinking methodologies.</li>
                <li>Advanced English level.</li>
              </ul>
            </ProgramDescriptionBody>
            <ProgramDescriptionTechs>
              <div className="title">Available for</div>
              <div className="techs-container">
                <div className="techs_container__tech">
                  <img src={UIIcon} alt="" />
                  <div className="tech-name">UI design</div>
                </div>
              </div>
            </ProgramDescriptionTechs>
            <ProgramDescriptionFooter>
              <Link href="#apply-now">
                <Button buttonType={!isMobile ? 'primary' : 'outlined-red'}>Apply now</Button>
              </Link>
            </ProgramDescriptionFooter>
          </ProgramDescriptionContainer>
        </Program>
      )}
      {(selectedProgram === 6 || selectedProgram === 0) && (
        <Program className={selectedProgram === 0 ? 'all' : ''}>
          <ProgramImageContainer>
            <ProgramImage src={QA} />
          </ProgramImageContainer>
          <ProgramDescriptionContainer>
            <ProgramDescriptionTitle>QA Automation</ProgramDescriptionTitle>
            <ProgramDescriptionBody>
              <div className="qualifications">
                <p>Qualifications</p>
              </div>
              <ul className="qs-list">
                <li>Software QA Bases.</li>
                <li>Ability to solve Logical problems.</li>
                <li>Basic knowledge of Java programming skills.</li>
                <li>Advanced English level.</li>
              </ul>
            </ProgramDescriptionBody>
            <ProgramDescriptionFooter>
              <Link href="#apply-now">
                <Button buttonType={!isMobile ? 'primary' : 'outlined-red'}>Apply now</Button>
              </Link>
            </ProgramDescriptionFooter>
          </ProgramDescriptionContainer>
        </Program>
      )}
      {(selectedProgram === 6 || selectedProgram === 0) && (
        <Program className={selectedProgram === 0 ? 'all' : 'not-first'}>
          <ProgramImageContainer>
            <ProgramImage src={QAFunctional} />
          </ProgramImageContainer>
          <ProgramDescriptionContainer>
            <ProgramDescriptionTitle>QA Functional</ProgramDescriptionTitle>
            <ProgramDescriptionBody>
              <div className="qualifications">
                <p>Qualifications</p>
              </div>
              <ul className="qs-list">
                <li>Software QA bases.</li>
                <li>Attention to details.</li>
                <li>Analytical and Problem-Solving Thinking.</li>
                <li>Communication and active listening.</li>
                <li>Teamwork.</li>
                <li>Advanced English level.</li>
              </ul>
            </ProgramDescriptionBody>
            <ProgramDescriptionFooter>
              <Link href="#apply-now">
                <Button buttonType={!isMobile ? 'primary' : 'outlined-red'}>Apply now</Button>
              </Link>
            </ProgramDescriptionFooter>
          </ProgramDescriptionContainer>
        </Program>
      )}
    </>
  );
};

export default ProgramController;
