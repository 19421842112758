import { createSlice } from '@reduxjs/toolkit';
import { thunkGetCities } from 'store/actions/country-api.action';

const initialState: { cities: string[]; loading: boolean } = {
  cities: [],
  loading: false,
};

export const countrySlice = createSlice({
  name: 'form',
  initialState: {
    value: initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkGetCities.fulfilled, (state, action) => {
      if (action.payload) state.value.cities = action.payload;
      else state.value.cities = [];
      state.value.loading = false;
    });
    builder.addCase(thunkGetCities.pending, (state) => {
      state.value.loading = true;
    });
    builder.addCase(thunkGetCities.rejected, (state) => {
      state.value.loading = false;
      state.value.cities = [];
    });
  },
});

export default countrySlice.reducer;
