import styled from 'styled-components';
import colors from 'styles/_colors';

export const CSImageSection = styled.div`
  & .image {
    display: block;
  }

  @media (min-width: 300px) {
    display: none;
  }

  @media (min-width: 760) {
    display: none;
  }

  @media (min-width: 761px) {
    display: block;
  }
`;

export const CSCContentSection = styled.div`
  max-width: 436px;
  width: 100%;
  align-self: start;
  justify-self: center;

  & .title {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    color: ${colors.applaudoRed};
    height: 72px;
  }

  & .subtitle {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: ${colors.applaudoNavyblue};
    max-width: 436px;
    width: 100%;
  }

  & .content-container {
    word-wrap: break-word;
    margin-top: 36px;
    max-width: 436px;
    width: 100%;
    color: ${colors.applaudoNavyblue};

    &__body {
      box-sizing: border-box;
      color: ${colors.applaudoNavyblue};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const TPMailAnchor = styled.a`
  display: inline-block;
  font-family: inherit;
  background-color: ${colors.applaudoRed};
  color: ${colors.white};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  padding: 8px 20px;
  word-wrap: break-word;

  @media (min-width: 500px) {
    font-size: 16px;
  }
`;
