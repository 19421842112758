import type { Identifier, XYCoord } from 'dnd-core';
import React, { PropsWithChildren, useRef } from 'react';
import { useMultiDrag, useMultiDrop } from 'react-dnd-multi-backend';
import type { DragItem } from 'utils/types/drag-and-drop.type';

import MobileDragPreview from './components/MobileDragPreview';
import { Draggable } from './components/draggable-item.components';

type DraggableItemType = PropsWithChildren<{
  className?: string;
  draggableType: string;
  id: string;
  index: number;
  onPosChange: (dragIndex: number, hoverIndex: number) => void;
}> &
  typeof defaultProps;

const defaultProps = { className: '' };

const DraggableItem = ({
  className,
  children,
  draggableType,
  id,
  index,
  onPosChange,
}: DraggableItemType) => {
  const draggableRef = useRef<HTMLDivElement>(null);
  const [[{ handlerId }, drop]] = useMultiDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: draggableType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!draggableRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = draggableRef.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onPosChange(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });
  const [[{ isDragging }, drag]] = useMultiDrag({
    type: draggableType,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(draggableRef));

  return (
    <>
      <Draggable
        opacity={isDragging ? 0 : 1}
        ref={draggableRef}
        className={className}
        data-handler-id={handlerId}
      >
        {children}
      </Draggable>
      <MobileDragPreview isDragging={isDragging}>{children}</MobileDragPreview>
    </>
  );
};

DraggableItem.defaultProps = defaultProps;
export default DraggableItem;
