import { ICombinedFaq, IListFaq, INormalFaq } from 'interfaces/faq.interfaces';

const faqList: (INormalFaq | IListFaq | ICombinedFaq)[] = [
  {
    id: 1,
    question: `What's the Trainee Program?`,
    answer: `It is a temporary 3-month program in which a select group of people with 
    the knowledge, skills and professional potential will prepare you intensively to 
    acquire a professional profile so Applaudo will be able to hire you and put you 
    into production.`,
  },
  {
    id: 2,
    question: 'What are the requirements to apply?',
    answer: [
      `Be over 18 years old `,
      `Time availability`,
      `Good internet connection `,
      `Intermediate or advanced level of English`,
    ],
  },
  {
    id: 3,
    question: 'What are the technical skills needed to enter the Trainee Program?',
    answer: [
      `Knowledge of object-oriented programming `,
      `Ability to solve logical problems `,
      `Potential to increase seniority level in the short term`,
    ],
  },
  {
    id: 4,
    question: 'Which Trainee Program I could apply to?',
    answer: `To any program of your preference that is currently active.`,
  },
  {
    id: 5,
    question: 'Which are the schedules?',
    answer: `Monday to Thursday from 9 A.M to 7 P.M and Friday from 9 A.M to 6 P.M CST.`,
  },
  {
    id: 6,
    question: 'Is the Trainee Program remote or face-to-face?',
    answer: `All the programs we offer are totally remote.`,
  },
  {
    id: 7,
    question: 'Does the Trainee Program have a cost?',
    answer: `No, it is totally free. It is a scholarship that Applaudo 
    offers to applicants to have a permanent position within the company.`,
  },
  {
    id: 8,
    question: 'How do I receive my remuneration?',
    answer: `Monthly remuneration is provided under the concept of professional services
    `,
  },
  {
    id: 9,
    question: `If I don't approve the test, when can I re-order a new attempt 
      from the Trainee Program?`,
    answer: `After 3 months of your last attempt. 
    Remember to put into practice the feedback provided during the evaluations`,
  },
  {
    id: 10,
    question: `How can I get feedback about the tests?`,
    answer: `You can request it directly by email at traineeprogram@applaudostudios.com`,
  },
  {
    id: 11,
    question: `At the end of the Trainee Program, can I apply to a position at Applaudo?`,
    answer:
      'Definitely, actually, the main objective of the program is for all the trainees that graduates to join Applaudo with a long-term relationship.',
  },
  {
    id: 12,
    question: `When can I request a new attempt from the Trainee Program again?`,
    answer: `Every 3 months to be able to implement the feedback provided during the evaluations.`,
  },
  {
    id: 13,
    question: `Can I apply to the Trainee Program while working full time?`,
    answer: `Unfortunately, no, our Trainee Program requires a full-time investment (9h).`,
  },
  {
    id: 14,
    question: `Can I apply to the Trainee Program if I am starting my college or 
    university career?`,
    answer: `Yes, you can apply to the Trainee Program even if you are in your first or last year 
    of the career, as long as you meet our technical requirements (see question 3) and our general 
    request and you have a full time to invest in our program.`,
  },
  {
    id: 15,
    question: `Do I need a university degree to join the Trainee Program?`,
    answer: `No, you don't need a college or university degree to join the Trainee Program,
     all you need is to meet our technical request (see question 3) and general request.`,
  },
  {
    id: 16,
    question: `Do I have to have a computer engineering background to apply to a Trainee Program?`,
    answer: `No, you just need to meet our technical requirements:`,
    requirements: [
      `Knowledge of Object-Oriented Programming`,
      `Ability to solve logical problems`,
      `Ability to communicate in English`,
    ],
  },
  {
    id: 17,
    question: `How many attempts I can try on the test?`,
    answer: `The number of attempts for the test is 3`,
  },
];

export default faqList;
