import styled from 'styled-components';
import breakpoints from 'styles/_breakpoints';
import colors from 'styles/_colors';

export const FormSectionImage = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  & img {
    width: 28em;
    top: 160px;
    position: absolute;
    left: 0;
    vertical-align: middle;
    border-style: none;
  }

  @media (min-width: 300px) {
    display: none;
  }

  @media (min-width: 680px) {
    display: none;
  }

  @media (min-width: 800px) {
    display: flex;
    min-width: 420px;

    & img {
      margin-left: -8%;
    }
  }

  @media (min-width: 1100px) {
    & img {
      width: 32em;
      margin-left: 0;
    }
  }
`;
export const FormSectionImageFooter = styled.div`
  margin-top: 750px;
  display: flex;
  flex-flow: column wrap;
  margin-left: 32px;

  @media (min-width: 1100px) {
    margin-left: 208px;
  }

  @media (min-width: 300px) {
    margin-top: 720px;
  }
  @media (min-width: 895px) {
    margin-top: 750px;
  }

  @media (min-width: ${breakpoints.maxWidth}) {
    margin-left: 208px;
  }

  & p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  & button {
    width: 320px;
  }
`;

export const FormContainer = styled.div`
  padding: 0 32px;

  @media (min-width: 500px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 800px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-right: 0;
  }

  @media (min-width: ${breakpoints.maxWidth}) {
    margin-right: 200px;
  }

  & .form-header {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;

    &__title {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;

      &__subtitle {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        color: ${colors.coolGray};
      }

      &__title {
        color: ${colors.applaudoRed};
        font-weight: 700;
        font-style: normal;
        font-size: 56px;
        line-height: 64px;
      }
      @media (min-width: 300px) {
        &__subtitle {
          font-size: 24px;
          line-height: 32px;
        }

        &__title {
          font-size: 36px;
          line-height: 48px;
        }
      }
      @media (min-width: 895px) {
        &__subtitle {
          font-size: 36px;
          line-height: 48px;
        }

        &__title {
          font-size: 56px;
          line-height: 64px;
        }
      }
    }

    &__progress {
      display: flex;
      flex-flow: row unwrap;
      align-items: center;

      &__line {
        margin: 0 10px;
        svg {
          width: 80px;
          @media (min-width: 500px) {
            width: 100px;
          }
        }
      }
    }
  }

  & .form {
    & .required-field {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;

  & .button-container {
    button {
      width: 100%;
    }
  }
`;

export const ControllWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  max-width: 444px;

  &.mb {
    margin-bottom: 20px;
  }

  & .MuiFormControl-root {
    width: 100%;
  }

  & .field-error {
    display: inline-block;
    color: ${colors.applaudoRed};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ColumnControlWrapper = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;

  &.mb {
    margin-bottom: 20px;
  }
`;

export const DragAndDropContainer = styled.div`
  position: relative;
  text-align: center;
  border: dashed 1px ${colors.applaudoNavyblue};
  position: relative;
  margin: 40px auto 0 auto;
  padding: 40px;
  background-color: ${colors.lightGray};
  color: ${colors.applaudoNavyblue};

  & input[type='file'] {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  & .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;

    &__secondary {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: block;
      margin: 0;
      padding: 0;
      margin-top: 16px;
      line-height: inherit;
    }
  }

  & .rules {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;

    &__select-file-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
      width: 174px;
      z-index: 3;
      margin-bottom: 8px;
    }
  }

  &.ondrag {
    color: ${colors.black};
    background: ${colors.mediumGray};
  }
`;

export const TermsAndConditions = styled.div`
  margin: 24px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  & span {
    color: ${colors.applaudoLightblue};
    cursor: pointer;
  }
`;

export const SecondSectionContainer = styled.div`
  & .button-container {
    button {
      width: 100%;
    }
  }
`;

export const SSJobsContainer = styled.div`
  & .description {
    max-width: 500px;
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0 0;
  }

  & .select-count {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0;
    color: ${colors.applaudoNavyblue};

    &.limit {
      color: ${colors.applaudoRed};
    }
  }

  & .tp-name {
    padding: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  & .categories-container {
    display: flex;
    flex-flow: column wrap;
    row-gap: 32px;
  }

  & .tp-container {
    margin-top: 16px;
    display: flex;
    flex-flow: row wrap;
    column-gap: 8px;
    row-gap: 16px;
    max-width: 500px;
  }

  & .selected-tps-container {
    display: flex;
    flex-flow: column wrap;
    row-gap: 16px;

    &__title {
      padding: 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }

    &__description {
      max-width: 500px;
      font-size: 16px;
      line-height: 24px;
    }

    &__items {
      margin-top: 16px;
      display: flex;
      flex-flow: column wrap;
      row-gap: 16px;
      align-items: stretch;

      & .item {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      }

      & .item-position {
        background-color: ${colors.lightGray};
        color: ${colors.applaudoNavyblue};
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: inline-flex;
        font-family: inherit;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        margin-right: 16px;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const TermsAndConditionsContainer = styled.div`
  margin-top: 16px;

  & .t-a-c__title {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
    color: ${colors.applaudoRed};
  }

  & .t-a-c__subtitle {
    list-style-type: none;
  }

  & .t-a-c__body {
    text-align: justify;
    font-size: 14px;
    line-height: 1.5715;
    a {
      color: #1890ff;
      text-decoration: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: color 0.3s;
    }
  }

  & .t-a-c__personal-data {
    text-align: justify;
    font-size: 14px;
    line-height: 1.5715;
    li {
      margin-bottom: 8px;
    }
  }

  .text-bold {
    font-weight: 700;
  }
`;

export const TermsAndConditionsTitle = styled.h4`
  font-size: 28px;
  font-weight: 700;
  text-align: center;
`;

export const TermsAndConditionsActionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const AlreadyRegisteredContainer = styled.div`
  & .form-container {
    margin-top: 56px;
    width: 100%;

    & .MuiFormControl-root {
      width: 100%;
    }
  }
  & .form-action {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 48px;

    @media (min-width: 420px) {
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 24px;
      margin-left: auto;
    }
  }
`;

export const AltRequestAttemptContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;

  & p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: inherit;
  }

  & .text-center {
    text-align: center;
  }

  & .full-width {
    width: 100%;
  }

  @media (min-width: 680px) {
    display: none;
  }
`;
