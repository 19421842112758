import ApiService from 'api/abstract/ApiService';
import { AxiosError, AxiosResponse } from 'axios';
import { ICandidate } from 'utils/interfaces/candidate.interface';

class CandidateService extends ApiService<ICandidate, undefined> {
  constructor() {
    super(process.env.REACT_APP_BACKEND_API as string, 'add-candidate');
  }

  async verifyCandidate(email: string): Promise<AxiosResponse<void> | AxiosError> {
    return this.axios.post('verify-candidate', {
      email,
    });
  }
}

export default CandidateService;
