import { Rectangle } from 'components/blue-rectangle/Rectangle';
import { Link } from 'components/red-section/components/red-section.components';
import React from 'react';
import colors from 'styles/_colors';

import { ReactComponent as FacebookLogo } from '../../assets/images/footer/facebook-logo.svg';
import { ReactComponent as InstagramLogo } from '../../assets/images/footer/instagram-logo.svg';
import { ReactComponent as LinkedinLogo } from '../../assets/images/footer/linkedin-logo.svg';
import { ReactComponent as TwitterLogo } from '../../assets/images/footer/twitter-logo.svg';
import { ApplaudoBrand, FooterContainer, FooterTitle } from './components/footer.components';

const Footer = () => (
  <Rectangle color={colors.applaudoNavyblue}>
    <FooterContainer>
      <div className="applaudo-brand-container">
        <p>Powered by</p>
        <ApplaudoBrand />
      </div>
      <div className="social-media-container">
        <FooterTitle>Follow us</FooterTitle>
        <div className="social-media-links">
          <Link
            href="https://www.facebook.com/applaudostudios"
            target="_blank"
            className="margin-0"
          >
            <FacebookLogo />
          </Link>
          <Link
            href="https://www.linkedin.com/company/3735640/"
            target="_blank"
            className="margin-0"
          >
            <LinkedinLogo />
          </Link>
          <Link href="https://twitter.com/applaudostudios" target="_blank" className="margin-0">
            <TwitterLogo />
          </Link>
          <Link
            href="https://www.instagram.com/applaudostudios/"
            target="_blank"
            className="margin-0"
          >
            <InstagramLogo />
          </Link>
        </div>
      </div>
    </FooterContainer>
  </Rectangle>
);

export default Footer;
