import { createAsyncThunk } from '@reduxjs/toolkit';
import JobService from 'api/services/JobService';
import { AxiosResponse } from 'axios';
import { GET_JOBS } from 'store/actions-types/jobs.action-type';
import { TPWithType } from 'utils/types/tp-program.type';

export const thunkGetJobs = createAsyncThunk(GET_JOBS, async () => {
  const jobService = new JobService();
  const { data } = (await jobService.get()) as AxiosResponse<TPWithType[]>;
  if (data) return data;

  return null;
});
