import styled from 'styled-components';

export const FileError = styled.div``;

export const FilePreviewContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 16px 0;
  padding: 8px 0;

  & .icon {
    margin-left: 20px;
  }

  & .file-name {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
  }

  & .clear {
    margin-left: auto;
    margin-right: 14px;
    cursor: pointer;
  }
`;
