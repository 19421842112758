import React, { useEffect, useState } from 'react';

type LinearProgressType = {
  size: number;
  firstSize: number;
  strokeWidth: number;
  percentage: number;
  color: string;
};

const LineProgress: React.FC<LinearProgressType> = (props) => {
  const { size, firstSize, strokeWidth, percentage, color } = props;
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const dash = (progress * size) / 100;

  return (
    <svg height="10" width={size}>
      <line
        x1="0"
        y1="0"
        x2={firstSize}
        y2="0"
        stroke="#ccc"
        strokeWidth={`${strokeWidth}px`}
        style={{ transition: 'all 0.5s' }}
      />
      <line
        x1="0"
        y1="0"
        x2={size}
        y2="0"
        stroke={color}
        strokeWidth={`${strokeWidth}px`}
        strokeDasharray={`${dash}, ${size - dash}`}
        style={{ transition: 'all 0.5s' }}
      />
    </svg>
  );
};

export default LineProgress;
