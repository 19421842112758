import { Rectangle } from 'components/blue-rectangle/Rectangle';
import faqList from 'data/faq-list';
import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import breakpoints from 'styles/_breakpoints';
import colors from 'styles/_colors';
import { useFadeAnimation } from 'utils/hooks/useFadeAnimation';

import faqSvg from '../../assets/images/faq-section/FAQ.svg';
import Faq from './components/Faq';
import { FaqImage, FaqList, FaqTitle } from './components/faq-section.components';

const FaqSectionWrapper = animated(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;
  position: relative;
  width: 100%;
  padding: 70px 0 150px 0;
  max-width: ${breakpoints.maxWidth};
  margin: 0 auto;

  & .faq-content-container {
    @media (min-width: ${breakpoints.tablet}) {
      display: flex;
      padding-left: 30px;
    }
  }

  & .title-mobile {
    @media (min-width: ${breakpoints.desktop}) {
      display: none;
    }
  }
`);

const FaqSection = () => {
  const { style, elementRef } = useFadeAnimation();

  return (
    <Rectangle id="fqs" color={colors.white}>
      <FaqSectionWrapper style={style} ref={elementRef}>
        <FaqTitle className="title-mobile">Frequently asked questions</FaqTitle>
        <div className="faq-content-container">
          <FaqImage src={faqSvg} alt="girl-svg" />
          <FaqList>
            <div className="title-desktop-container">
              <FaqTitle className="title-desktop">Frequently asked questions</FaqTitle>
            </div>
            {faqList.map((faq) => (
              <Faq faq={faq} key={faq.id} />
            ))}
          </FaqList>
        </div>
      </FaqSectionWrapper>
    </Rectangle>
  );
};

export default FaqSection;
