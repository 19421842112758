import { Rectangle } from 'components/blue-rectangle/Rectangle';
import Button from 'components/button/Button';
import React from 'react';
import colors from 'styles/_colors';

import {
  TermsAndConditionsActionContainer,
  TermsAndConditionsContainer,
} from './form-section.components';

type TermsAndConditionsDialogProps = {
  handleClose: () => void;
};

const TermsAndConditionsDialog = ({ handleClose }: TermsAndConditionsDialogProps) => (
  <Rectangle color={colors.white}>
    <TermsAndConditionsContainer>
      <p className="t-a-c__title">1. Introduction</p>
      <p className="t-a-c__body">
        We are a company and online platform specialized in software development. We furnish and
        create tech solutions, including without limitation UI/UIX Design, artificial intelligence,
        data analytics, virtual reality and Internet of things. People use our Services to find
        software solutions for their corporate and commercial needs, and as part of our recruitment
        and training processes we use our website to obtain information for academic and/or
        professional position applicants. Our Privacy terms here to apply to any Visitor to our
        Services.
      </p>
    </TermsAndConditionsContainer>
    <TermsAndConditionsContainer>
      <p className="t-a-c__title">2. Data We Collect</p>
      <ul className="t-a-c__subtitle">
        <li className="text-bold">Data you provide to us</li>
      </ul>
      <p className="t-a-c__body">
        We only collect data you provide to us. As a visiting user to our site, and applicant to our
        careers module, training program and/or job opportunities section you may share your
        professional identity, and share with us wage expectations, work expectations and
        professional information, as well as information relating to your location. Other
        information you may provide can include preceding source, Identification Number and/or
        passport number, e-mail address, phone number, and your resumé.
      </p>
      <ul className="t-a-c__subtitle">
        <li className="text-bold">Your Device and Location</li>
      </ul>
      <p className="t-a-c__body">
        We receive data through cookies and similar technologies, but for the purpose set herein as
        applicant, no data obtained via your device or location is used, unless directly submitted
        by you. Please refer to our &nbsp;
        <a href="https://applaudostudios.com/policy" target="_blank" rel="noopener noreferrer">
          cookie policy
        </a>
        &nbsp; for more information about Cookies and location information.
      </p>
    </TermsAndConditionsContainer>
    <TermsAndConditionsContainer>
      <p className="t-a-c__title">3. How We Use Your Data</p>
      <p className="t-a-c__body">
        We use your data to analyse your application as well as the location you provide to search
        in different applicant&#39;s locations based on demographics and on data analytic research
        that allow us to make more efficient screening of potential applicants based on the location
        of our accepted applicants.
      </p>
      <p className="t-a-c__body">
        We may also use your data to share it with our partners in order to secure more information
        about potential candidates for academic training programs. From this last use, we may use
        your contact location to offer you through one of our partners, further training programs in
        case you were not to be selected as part of the application process under which you
        submitted your application. You may contact us if you wish your information to be eliminated
        from our databases or that of our partners.
      </p>
    </TermsAndConditionsContainer>
    <TermsAndConditionsContainer>
      <p className="t-a-c__title">4. Communications</p>
      <p className="t-a-c__body">
        We, or our partners may contact you through email, mobile phone, or any other contact data
        you may have submitted with your resumé, and other ways through our Services, including text
        messages and push notifications. We may send you messages about the availability of
        positions, even if it is different from the one requested, and/or for seminars, training and
        modules available directly through us or any of our partners. Please be aware that you
        cannot opt out of receiving service messages from us, including security and legal notices.
      </p>
    </TermsAndConditionsContainer>
    <TermsAndConditionsContainer>
      <p className="t-a-c__title">5. Archival and Data Retention</p>
      <p className="t-a-c__body">
        Your information will be archived in our databases located in the United States for an
        undefined period and as long as necessary after initial submission by you. After this term,
        or per direct request from you, your information will be deleted from our databases.
      </p>
    </TermsAndConditionsContainer>
    <TermsAndConditionsContainer>
      <p className="t-a-c__title">6. Legal Disclosures</p>
      <p className="t-a-c__body">
        We may need to share your data when we believe it&#39;s required by law or to help protect
        the rights and safety of you, us or others.
      </p>
      <p className="t-a-c__body">
        It is possible that we will need to disclose information about you when required by law,
        subpoena, or other legal process or if we have a good faith belief that disclosure is
        reasonably necessary to &#40;1&#41; investigate, prevent or take action regarding suspected
        or actual illegal activities or to assist government enforcement agencies; &#40;2&#41;
        investigate and defend ourselves against any third-party claims or allegations; &#40;3&#41;
        protect the security or integrity of our Services &#40;such as by sharing with companies
        facing similar threats&#41;; or &#40;4&#41; exercise or protect the rights and safety of
        Applaudo, our partners, personnel or others.
      </p>
    </TermsAndConditionsContainer>
    <TermsAndConditionsContainer>
      <p className="t-a-c__title">7. Rights to Access and Control Your Personal Data</p>
      <p className="t-a-c__body">
        You can access or delete your personal data as per requested via e-mail or contacting us
        through our website.
      </p>
      <p className="t-a-c__body">For personal data that we have about you, you can:</p>
      <ul className="t-a-c__personal-data">
        <li>
          <span className="text-bold">Delete Data</span>: You can ask us to erase or delete all or
          some of your personal data via a written email.
        </li>
        <li>
          <span className="text-bold">Change or Correct Data</span>: You can edit some of your
          personal data through your account. You can also ask us to change, update or fix your data
          in certain cases, particularly if it&#39;s inaccurate.
        </li>
        <li>
          <span className="text-bold">Object to, or Limit or Restrict, Use of Data</span>: You can
          ask us to stop using all or some of your personal data &#40;e.g., if we have no legal
          right to keep using it&#41; or to limit our use of it &#40;e.g., if your personal data is
          inaccurate or unlawfully held&#41;.
        </li>
        <li>
          <span className="text-bold">Right to Access and/or Take Your Data</span>: You can ask us
          for a copy of your personal data and can ask for a copy of personal data you provided in
          machine readable form. written email.
        </li>
      </ul>
    </TermsAndConditionsContainer>
    <TermsAndConditionsContainer>
      <p className="t-a-c__title">8. Security</p>
      <p className="t-a-c__body">
        We monitor for and try to prevent security breaches. Please use the security features
        available through our Services.
      </p>
      <p className="t-a-c__body">
        We implement security safeguards designed to protect your data, such as HTTPS. We regularly
        monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the
        security of any information that you send us. There is no guarantee that data may not be
        accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or
        managerial safeguards.
      </p>
    </TermsAndConditionsContainer>
    <TermsAndConditionsContainer>
      <p className="t-a-c__title">9. Cross-Border Data Transfers</p>
      <p className="t-a-c__body">We store and use your data outside your country.</p>
      <p className="t-a-c__body">
        We may process data outside of the United States and across Latin America and rely on
        legally-provided mechanisms to lawfully transfer data across borders. Countries where we
        process data may have laws which are different from, and potentially not as protective as,
        the laws of your own country.
      </p>
    </TermsAndConditionsContainer>
    <TermsAndConditionsActionContainer>
      <Button buttonType="primary" onClick={handleClose}>
        Close
      </Button>
    </TermsAndConditionsActionContainer>
  </Rectangle>
);

export default TermsAndConditionsDialog;
