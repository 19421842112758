import { animated } from 'react-spring';
import styled from 'styled-components';

import colors from '../../../styles/_colors';

export const DisapeerTopBar = animated(styled.div`
  min-height: 80px;
  width: 100%;
  background: ${colors.white};
  display: inline-flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
`);

export const BrandContainer = styled.div`
  width: 40%;
  padding-left: 32px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  display: flex;
  & img {
    width: 136px;
    @media (min-width: 700px) {
      width: 176px;
    }
  }
}
`;

export const SideDrawerMenu = styled.div`
  height: 100%;
  background: white;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;

  & ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

  & li {
    margin: 0.5rem 0;
    a {
      button {
        margin-left: 0;
        .reddiv {
          margin-top: 0.5rem;
        }
      }
    }
  }

  &.open {
    transform: translateX(0);
  }

  @media screen and (min-width: 759px) {
    display: none;
  }
`;

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.3); */
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;
