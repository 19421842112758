import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Rectangle } from 'components/blue-rectangle/Rectangle';
import Button from 'components/button/Button';
import React, { useState } from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { useAppSelector } from 'utils/hooks/useAppSelector';
import { useFadeAnimation } from 'utils/hooks/useFadeAnimation';

import ManComputer from '../../assets/images/man-computer.svg';
import colors from '../../styles/_colors';
import ClosedSection from './components/closed-section';
import FirstSection from './components/first-section';
import FormProgressBar from './components/form-progress-bar';
import {
  AltRequestAttemptContainer,
  FormContainer,
  FormSectionImage,
  FormSectionImageFooter,
} from './components/form-section.components';
import RequestNewAttemptComponent from './components/request-new-attempt-component';
import SecondSection from './components/second-section';
import SuccessSection from './components/success-section';

const FormWrapper = animated(styled.div`
  width: 100%;
  padding: 160px 0 80px 0;
  background-color: ${colors.white};
  @media (min-width: 680px) {
    display: flex;
  }
`);

const FormSection = () => {
  const { style, elementRef } = useFadeAnimation(true);
  const [open, setOpen] = useState(false);
  const formState = useAppSelector((state) => state.form.value.state);
  const openSeason = useAppSelector((state) => state.form.value.isOpenSeason);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Rectangle id="apply-now" color={colors.white}>
      {formState <= 1 && openSeason && (
        <FormWrapper ref={elementRef} style={style}>
          <FormSectionImage>
            <img src={ManComputer} alt="" />
            <FormSectionImageFooter>
              <p>Already registered? Request a new attempt!</p>
              <Button
                buttonType="outlined-gray"
                className="full-width"
                color="#ff4040"
                onClick={() => setOpen(true)}
              >
                Request new attempt
              </Button>
            </FormSectionImageFooter>
          </FormSectionImage>
          <FormContainer>
            <div className="form-header">
              <div className="form-header__title">
                <div className="form-header__title__subtitle">Register to join our</div>
                <div className="form-header__title__title">Trainee Program</div>
              </div>
              <div className="form-header__progress">
                <FormProgressBar />
              </div>
            </div>
            <div className="form">
              {formState <= 1 && <div className="required-field">*Indicates a required field</div>}

              {formState === 0 && (
                <>
                  <FirstSection />
                  <AltRequestAttemptContainer>
                    <p className="text-center">Already registered? Request a new attempt!</p>
                    <Button
                      buttonType="outlined-gray"
                      className="full-width"
                      color="#ff4040"
                      onClick={() => setOpen(true)}
                    >
                      Request new attempt
                    </Button>
                  </AltRequestAttemptContainer>
                </>
              )}

              {formState === 1 && <SecondSection />}
            </div>
          </FormContainer>
        </FormWrapper>
      )}
      {formState === 2 && <SuccessSection />}
      {!openSeason && <ClosedSection />}

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <span style={{ fontSize: '28px', fontWeight: 700 }}>Already registered</span>
          <CloseIcon
            aria-label="close"
            onClick={handleClose}
            style={{ cursor: 'pointer' }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          />
        </DialogTitle>
        <DialogContent>
          <RequestNewAttemptComponent handleClose={handleClose} isOpenSeason={openSeason} />
        </DialogContent>
      </Dialog>
    </Rectangle>
  );
};

export default FormSection;
