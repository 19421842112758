import { Clear } from '@mui/icons-material';
import { ReactComponent as ClipIcon } from 'assets/images/clip.svg';
import React from 'react';

import { FilePreviewContainer } from './drag-and-drop.components';

type FilePreviewType = {
  file: File;
  setFile: (file: File | null) => void;
};

const FilePreview = (props: FilePreviewType) => {
  const { file, setFile } = props;
  return (
    <FilePreviewContainer>
      <div className="icon">
        <ClipIcon />
      </div>
      <div className="file-name">
        {file.name.length >= 20 ? `${file.name.substring(0, 20)}...` : file.name}
      </div>
      <div className="clear">
        <Clear onClick={() => setFile(null)} />
      </div>
    </FilePreviewContainer>
  );
};

export default FilePreview;
