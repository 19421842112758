import { QueryParamsTypes } from '../types/query-params.type';

const queryParams = (params: QueryParamsTypes) => {
  let stringParams = '?';

  Object.keys(params).forEach((key, index, keys) => {
    stringParams += `${key}=${params[key]}`;
    if (key === keys[keys.length - 1] && params[key] === params[keys.length - 1]) {
      stringParams += '&';
    }
  });

  return stringParams;
};

export default queryParams;
