import styled from 'styled-components';

import colors from '../../../styles/_colors';

export const TitleWrapper = styled.div`
  position: relative;
  @media (min-width: 300px) {
    padding: 0 14px 0 14px;
  }

  @media (min-width: 769px) {
    padding: 0 32px 0 32px;
  }
`;

export const Title = styled.h1`
  color: ${colors.applaudoRed};
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px 0;
  margin: 0;

  @media (min-width: 300px) {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 64px;
    margin-bottom: 32px;
    &.beneffit-title {
      margin-bottom: 16px;
    }
  }

  @media (min-width: 769px) {
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    margin-bottom: 80px;
    &.beneffit-title {
      margin-bottom: 16px;
    }
  }
`;

export const Subtitle = styled.p`
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 4px 0;
  @media (min-width: 700px) {
    font-weight: 600;
  }
`;

export const IconsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 14px;
  grid-auto-flow: column;
  gap: 4px;
  justify-content: center;
  align-content: center;
  grid-template-areas:
    'column-2 column-3'
    'column-4 column-5'
    'column-1 column-1';

  @media (min-width: 700px) {
    padding: 48px 64px 0 64px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: 'column-1 column-2 column-3 column-4 column-5';
  }
`;

export const Beneffit = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 4px;
  text-align: center;
  max-width: 124px;
  margin: 0 auto;
  position: relative;
  color: ${colors.white};

  &:nth-child(1) {
    grid-area: column-1;
  }
  &:nth-child(2) {
    grid-area: column-2;
  }
  &:nth-child(3) {
    grid-area: column-3;
  }
  &:nth-child(4) {
    grid-area: column-4;
  }
  &:nth-child(5) {
    grid-area: column-5;
  }

  @media (min-width: 300px) {
    &.last {
      flex-flow: row nowrap;
    }
  }

  @media (min-width: 500px) {
    grid-column: auto;
    &.last {
      flex-flow: column wrap;
    }
  }
`;

export const BeneffitIcon = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.applaudoRed};
  padding-bottom: 20px;
  position: relative;

  svg {
    font-size: 50px;
  }

  @media (min-width: 300px) {
    &.last__icon {
      justify-content: center;
      align-items: center;
      margin-right: 60px;
      margin-left: -40px;
    }
  }

  @media (min-width: 500px) {
    &.last__icon {
      margin: 0;
    }
  }
`;

export const BeneffitDescription = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
