import Benefits from 'components/benefits/Beneffits';
import FaqSection from 'components/faq-section/FaqSection';
import Footer from 'components/footer/Footer';
import FormSection from 'components/form-section/FormSection';
import MeetOurTrainees from 'components/meet-our-trainees/MeetOurTrainees';
import RecruitingProcess from 'components/recruiting-process/RecruitingProcess';
import TopBar from 'components/top-bar/TopBar';
import { BackDrop } from 'components/top-bar/components/disapeer-top-bar';
import SideDrawer from 'components/top-bar/components/side-drawer';
import TPPrograms from 'components/tp-programs/TPPrograms';
import TPD from 'components/trainee-program-description/TraineeProgramDescription';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { saveLocation, saveOpenSeason, saveSource } from 'store/slice/form';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const getPosition = useCallback(
    (data: GeolocationPosition) => {
      const { latitude, longitude } = data.coords;
      dispatch(saveLocation({ latitude, longitude }));
    },
    [dispatch]
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(getPosition);
  }, [getPosition]);

  useEffect(() => {
    const queries = queryString.parse(window.location.search);
    if (queries && queries.utm_source && typeof queries.utm_source === 'string') {
      dispatch(saveSource(queries.utm_source));
    }
    if (queries && queries.closed && typeof queries.closed === 'string') {
      const isOpenSeason = queries.closed.toLocaleLowerCase() === 'false';
      dispatch(saveOpenSeason(isOpenSeason));
    }
  }, [dispatch]);

  useEffect(() => {
    const location = window.location.host;
    const queries = window.location.search;
    if (location.match(/apply/)) {
      window.location.href = `https://trainee.applaudostudios.com${queries}`;
    }
  }, []);

  return (
    <div className="App">
      <TopBar toggleClickHandler={() => setSideDrawerOpen((prevState) => !prevState)} />
      <SideDrawer show={sideDrawerOpen} hideDrawer={() => setSideDrawerOpen(false)} />
      {sideDrawerOpen && <BackDrop onClick={() => setSideDrawerOpen(false)} />}
      <FormSection />
      <TPD />
      <TPPrograms />
      <Benefits />
      <RecruitingProcess />
      <MeetOurTrainees />
      <FaqSection />
      <Footer />
    </div>
  );
};

export default Home;
