import { ICombinedFaq, IListFaq, INormalFaq } from 'interfaces/faq.interfaces';
import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import colors from 'styles/_colors';

import Answer from './Answer';
import { CloseIcon, FaqContainer, OpenIcon } from './faq-section.components';

type FaqProps = {
  faq: INormalFaq | IListFaq | ICombinedFaq;
};
const AnimatedDiv = animated(styled.div<{ isOpen: boolean }>`
  width: 100%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-top: ${(props) => (props.isOpen ? `1px solid ${colors.applaudoRed}` : 'none')};
`);

const Faq = ({ faq }: FaqProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const animatedProps = useSpring({
    from: {
      height: 0,
      display: 'none',
      opacity: 0,
    },

    to: {
      height: 'auto',
      display: 'block',
      opacity: 1,
    },

    config: {
      duration: 250,
    },
    reverse: !isOpen,
    reset: isOpen,
  });

  return (
    <FaqContainer isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      <div className="faq-question">
        <div>{isOpen ? <CloseIcon /> : <OpenIcon />}</div>
        <p>{faq.question}</p>
      </div>
      <AnimatedDiv style={{ ...animatedProps }} isOpen={isOpen}>
        <Answer faq={faq} />
      </AnimatedDiv>
    </FaqContainer>
  );
};

export default Faq;
