import styled from 'styled-components';

import colors from '../../../styles/_colors';

type ButtonProps = {
  buttonType: 'outlined' | 'primary';
};

export const LinkButtonConstainer = styled.div`
  width: 60%;
  -webkit-box-pack: end;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 760px) {
    display: none;
  }
`;

export const LinkButton = styled.button<ButtonProps>`
  background: ${(props: ButtonProps) => {
    switch (props.buttonType) {
      case 'outlined':
        return 'transparent';
      case 'primary':
        return colors.applaudoRed;
      default:
        return colors.applaudoRed;
    }
  }};
  cursor: pointer;
  color: ${(props: ButtonProps) => {
    switch (props.buttonType) {
      case 'outlined':
        return colors.applaudoNavyblue;
      case 'primary':
        return colors.white;
      default:
        return colors.white;
    }
  }};
  height: 80px;
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  justify-content: center;
  margin: ${(props) => (props.buttonType === 'primary' ? '0 0 0 0.75rem' : '0 0.75rem')};
  list-style-type: none;
  border-width: 0px;
  font-style: normal;
  font-weight: ${(props) => (props.buttonType === 'primary' ? 600 : 'normal')};
  font-size: 16px;
  line-height: 24px;
  padding: ${(props) => (props.buttonType === 'primary' ? '28px 48px' : '28px 0.5rem 0 0.5rem')};

  &.bold {
    font-weight: 600;
    font-size: 1.2rem;
  }

  ${(props) =>
    props.buttonType === 'outlined' &&
    `&:hover .reddiv {
    display: flex;
    align-self: center;
    height: 2px;
    width: 75%;
    transform: scaleX(1.4);
    background-color: ${colors.applaudoRed};
    transition: width 0.4s ease 0s;
  }

  & .reddiv {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    height: 2px;
    width: 0px;
    margin-top: 1.5rem;
    background-color: ${colors.applaudoRed};
  }`}
`;

export const BurgerButton = styled.button`
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: 16px;

  &:focus {
    outline: none;
  }

  .toggle-button-line {
    width: 30px;
    height: 2px;
    background: black;
  }

  @media screen and (min-width: 759px) {
    display: none;
  }
`;
