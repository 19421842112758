import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import { ReactComponent as HeadIcon } from 'assets/images/head.svg';
import { ReactComponent as WorkFromHomeIcon } from 'assets/images/house.svg';
import { ReactComponent as MedallIcon } from 'assets/images/medall.svg';
import { ReactComponent as ShuffleIcon } from 'assets/images/shuffle.svg';
import { ReactComponent as TeacherIcon } from 'assets/images/teacher.svg';
import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { useFadeAnimation } from 'utils/hooks/useFadeAnimation';

import colors from '../../styles/_colors';
import { Rectangle as BlueRectangle } from '../blue-rectangle/Rectangle';
import {
  Beneffit,
  BeneffitDescription,
  BeneffitIcon,
  IconsWrapper,
  Subtitle,
  Title,
  TitleWrapper,
} from './components/beneffits.components';

const BeneffitsWrapper = animated(styled.div`
  @media (min-width: 300px) {
    padding: 80px 16px;
  }

  @media (min-width: 769px) {
    padding: 80px 32px;
  }
`);

const Benefits = () => {
  const { style, elementRef } = useFadeAnimation();
  return (
    <BlueRectangle color={colors.applaudoNavyblue}>
      <BeneffitsWrapper ref={elementRef} style={style}>
        <TitleWrapper>
          <Title className="beneffit-title">Benefits of Trainee Program</Title>
          <Subtitle>Joining our Trainee Program gives you access to perks and benefits</Subtitle>
        </TitleWrapper>
        <IconsWrapper>
          <Beneffit className="last">
            <BeneffitIcon className="last__icon">
              <TeacherIcon />
            </BeneffitIcon>
            <BeneffitDescription>Training and Development Opportunities</BeneffitDescription>
          </Beneffit>
          <Beneffit>
            <BeneffitIcon>
              <CakeOutlinedIcon />
            </BeneffitIcon>
            <BeneffitDescription>Celebrations and events</BeneffitDescription>
          </Beneffit>
          <Beneffit>
            <BeneffitIcon>
              <MedallIcon />
            </BeneffitIcon>
            <BeneffitDescription>Recognizing activities</BeneffitDescription>
          </Beneffit>
          <Beneffit>
            <BeneffitIcon>
              <WorkFromHomeIcon />
            </BeneffitIcon>
            <BeneffitDescription>Work from home </BeneffitDescription>
          </Beneffit>
          <Beneffit>
            <BeneffitIcon>
              <ShuffleIcon />
            </BeneffitIcon>
            <BeneffitDescription>Flexibility</BeneffitDescription>
          </Beneffit>
        </IconsWrapper>
      </BeneffitsWrapper>
    </BlueRectangle>
  );
};

export default Benefits;
