import React from 'react';
import { JsonLd } from 'react-schemaorg';
import Home from 'views/home/Home';

import './App.scss';

const App = () => (
  <>
    <Home />
    <JsonLd
      item={{
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: "1. What's the Trainee Program?",
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                'It is a temporary 3-month program in which a select group of people with ' +
                'the knowledge, skills and professional potential will prepare you intensively ' +
                'to acquire a professional profile so Applaudo will be able to hire you ' +
                'and put you into production.',
            },
          },
          {
            '@type': 'Question',
            name: '1. What are the requirements to apply to the Trainee Program?',
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                'Be over 18 years old \n' +
                'Time availability \n' +
                'Good internet connection \n' +
                'Live in LATAM \n' +
                'Intermediate or advanced level of English',
            },
          },
          {
            '@type': 'Question',
            name: '1. What are the technical skills needed to enter the Trainee Program?',
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                'Knowledge of object-oriented programming \n' +
                'Ability to solve logical problems \n' +
                'Potential to increase seniority level in the short term',
            },
          },
          {
            '@type': 'Question',
            name: 'Which Trainee Program I could apply to?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'To any program of your preference that is currently active.',
            },
          },
          {
            '@type': 'Question',
            name: 'Which are the schedules for the Trainee Program?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Monday to Thursday from 9 AM to 7 PM and Friday from 9 AM to 6 PM.',
            },
          },
          {
            '@type': 'Question',
            name: 'Is the Trainee Program remote or face-to-face?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'All the programs we offer are totally remote.',
            },
          },
          {
            '@type': 'Question',
            name: 'Does the Trainee Program have a cost?',
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                'No, it is totally free. ' +
                'It is a scholarship that Applaudo offers to applicants to ' +
                'have a permanent position within the company.',
            },
          },
          {
            '@type': 'Question',
            name: 'How do I receive my remuneration?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Monthly remuneration is provided under the concept of professional services',
            },
          },
          {
            '@type': 'Question',
            name:
              "1. If I don't approve the test, " +
              'when can I re-order a new attempt from the Trainee Program?',
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                'After 3 months of your last attempt. ' +
                'Remember to put into practice the feedback provided during the evaluations',
            },
          },
          {
            '@type': 'Question',
            name: 'How can I get feedback about the Trainee Program test?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'You can request it directly by email at traineeprogram@applaudostudios.com',
            },
          },
          {
            '@type': 'Question',
            // eslint-disable-next-line no-useless-concat
            name: 'At the end of the Trainee Program, ' + 'can I apply to a position at Applaudo?',
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                'Definitely, actually, the main objective of' +
                'the program is to be able to employ each trainee that graduates.',
            },
          },
          {
            '@type': 'Question',
            name: '1. When can I request a new attempt from the Trainee Program again?',
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                'Every 3 months to be able to implement the ' +
                'feedback provided during the evaluations',
            },
          },
          {
            '@type': 'Question',
            name: '1. Can I apply to the Trainee Program while working full time?',
            acceptedAnswer: {
              '@type': 'Answer',
              text: 'Unfortunately, no, our Trainee Program requires a full-time investment (9h).',
            },
          },
          {
            '@type': 'Question',
            name:
              'Can I apply to the Trainee Program if I am ' +
              'starting my college or university career?',
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                'Yes, you can apply to the Trainee Program even ' +
                'if you are in your first or last year of the career, ' +
                'as long as you meet our technical requirements (see question 3) ' +
                'and our general request and you have a full time to invest in our program.',
            },
          },
          {
            '@type': 'Question',
            name: 'Do I need a university degree to join the Trainee Program?',
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                "No, you don't need a college or university degree to join the Trainee Program, " +
                'all you need is to meet our technical request ' +
                '(see question 3) and general request.',
            },
          },
          {
            '@type': 'Question',
            name:
              'Do I have to have a computer engineering background ' +
              'to apply to a Trainee Program?',
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                'No, you just need to meet our technical requirements: \n' +
                '• Knowledge of Object-Oriented Programming \n' +
                '• Ability to solve logical problems \n' +
                '• Ability to communicate in English',
            },
          },
          {
            '@type': 'Question',
            name: 'How many attempts I can try on the test?',
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                "The number of attempts for the test is 3 then you'll" +
                'have to wait 3 months and request a new attempt.',
            },
          },
        ],
      }}
    />
  </>
);

export default App;
