import styled from 'styled-components';

import colors from '../../../styles/_colors';

export const RedSectionTitleWrapper = styled.div`
  margin-top: 16rem;
  margin-bottom: 5rem;
  height: auto;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

export const RedSectionTitle = styled.div`
  display: flex;
  flex-flow: column wrap;
  -webkit-box-align: center;
  place-items: center;
  z-index: 2;

  @media (min-width: 768px) {
    & {
      margin-left: 1%;
      margin-right: 1%;
    }
  }
  @media (min-width: 992px) {
    margin-left: 2%;
    margin-right: 2%;
  }
  @media (min-width: 1200px) {
    margin-left: 2%;
    margin-right: 5%;
  }

  @media (min-width: 1400px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 1146px;
  }
`;

export const Title = styled.h1`
  color: ${colors.white};
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  padding: 0px 1.25rem;
  margin-top: 0px;
  margin-bottom: 0px;

  @media (min-width: 300px) {
    font-size: 56px;
    padding: 0px 1rem;
  }

  @media (min-width: 768px) {
    font-size: 56px;
    padding: 0px 1rem;
  }

  @media (min-width: 992px) {
    line-height: 104px;
    fonst-size: 24px;
  }

  @media (min-width: 1200px) {
    font-size: 56px;
    line-height: 104px;
    padding: 0px 1.25rem;
  }
`;

export const Subtitle = styled.p`
  padding-bottom: 0px;
  line-height: 1.625rem;
  letter-spacing: 0.025em;
  font-size: 1rem;
  text-align: center;
  color: ${colors.white};
  margin: 1rem auto 2rem;
  z-index: 30;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 670px;

  @media (min-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: 992px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: 1200px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const RedSectionAction = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  flex-flow: row nowrap;
`;

export const Link = styled.a`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  width: 100%;
  margin: 0.75rem 0.5rem;
  text-decoration: none;

  &.margin-0 {
    margin: 0 !important;
  }

  @media (min-width: 400px) {
    width: auto;
    margin: 0 0.75rem;
  }
`;

export const RedSectionImageWrapper = styled.div`
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  height: auto;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  position: absolute;
  margin-top: 3rem;
  -webkit-box-align: center;
  align-items: center;
  z-index: 1;

  @media (min-width: 768px) {
    width: 100%;
    margin-top: 0px;
  }

  @media (min-width: 992px) {
    width: auto;
    margin-top: 0px;
  }

  @media (min-width: 1200px) {
    width: auto;
    margin-top: 0px;
  }
`;

export const RedSectionImage = styled.img`
  width: 83.3333%;
  padding-top: 2rem;

  @media (min-width: 768px) {
    width: 60%;
  }

  @media (min-width: 1200px) {
    width: 75%;
  }
`;
