import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import CandidateService from 'api/services/CandidateService';
import Button from 'components/button/Button';
import { MuiTelInput, MuiTelInputCountry } from 'mui-tel-input';
import React, { BaseSyntheticEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { thunkGetCities, thunkGetCountries } from 'store/actions/country-api.action';
import {
  saveFirstSection,
  saveFormState,
  verifyCandidateFailure,
  verifyCandidateStart,
  verifyCandidateSuccess,
} from 'store/slice/form';
import { ERRORS } from 'utils/constants/error-messages';
import { buildFirstSection } from 'utils/functions/buildFormData';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useAppSelector';
import { IFormValues } from 'utils/interfaces/form.interface';
import { emailPattern, namePattern } from 'utils/patterns/custom-patterns';
import { phoneValidator } from 'utils/validators/phone.validator';
import { EmptyValidator } from 'utils/validators/required.validator';

import { ColumnControlWrapper, ControllWrapper, Form } from './form-section.components';

const FirstSection = () => {
  const dispatch = useAppDispatch();
  const countries = useAppSelector(({ country }) => country.value.countries);
  const allCountries = useAppSelector(({ country }) => country.value.listOfCountries);
  const dialCodes = useAppSelector(({ country }) => country.value.listOfDialCodes);
  const citySelector = useAppSelector(({ city }) => city.value);
  const form = useRef<HTMLFormElement | null>(null);
  const emailValidationState = useAppSelector(({ form: { value } }) => value.verifyCandidate);
  const { control, formState, getValues, setValue, handleSubmit, setError } = useForm({
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: {
      name: '',
      lastName: '',
      phone: '',
      email: '',
      confirmEmail: '',
      documentType: 'ID',
      documentId: '',
      country: '',
      city: '',
      countryCode: 'SV',
    },
  });

  const onChangeCountry = useCallback(
    (value: string) => {
      const localDialCode = dialCodes[value];
      if (localDialCode) {
        setValue('country', value);
        setValue('city', '');
        setValue('countryCode', localDialCode, { shouldValidate: true });
      }
      dispatch(thunkGetCities(allCountries[value]));
    },
    [dialCodes, setValue, dispatch, allCountries]
  );

  const onSubmit = useCallback(
    async (data: IFormValues, e: BaseSyntheticEvent | undefined) => {
      e?.preventDefault();
      try {
        const candidateService = new CandidateService();
        const firstSectionData = buildFirstSection(data);
        dispatch(verifyCandidateStart());
        await candidateService.verifyCandidate(firstSectionData.email);
        dispatch(saveFormState(1));
        dispatch(saveFirstSection(firstSectionData));
        dispatch(verifyCandidateSuccess());
      } catch (error) {
        dispatch(verifyCandidateFailure(ERRORS.EMAIL_ALREADY_EXISTS));
        setError('email', { message: ERRORS.EMAIL_ALREADY_EXISTS }, { shouldFocus: true });
      }
    },
    [dispatch, setError]
  );

  useEffect(() => {
    dispatch(thunkGetCountries());
  }, [dispatch]);

  useEffect(() => {
    setValue('documentType', 'ID');
  }, [setValue]);

  const currentCountryCode = useMemo(
    () => (getValues('countryCode') as unknown as MuiTelInputCountry) ?? 'SV',
    [getValues('country'), getValues('countryCode'), getValues]
  );

  return (
    <Form ref={form} onSubmit={handleSubmit(onSubmit)}>
      <ControllWrapper className="mb">
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Name field is required',
            pattern: {
              value: namePattern,
              message: 'This field only accepts letters',
            },
            validate: (value) => EmptyValidator(value, 'Name field is required'),
          }}
          render={({ field }) => (
            <TextField
              label="*Name"
              {...field}
              autoComplete="none"
              error={!!formState.errors.name}
              variant="standard"
            />
          )}
        />
        {formState.touchedFields.name && formState.errors.name && (
          <div className="field-error">{formState.errors.name.message}</div>
        )}
      </ControllWrapper>

      <ControllWrapper className="mb">
        <Controller
          name="lastName"
          control={control}
          rules={{
            required: 'Last name field is required',
            pattern: {
              value: namePattern,
              message: 'This field only accepts letters',
            },
            validate: (value) => EmptyValidator(value, 'Last name field is required'),
          }}
          render={({ field }) => (
            <TextField
              label="*Last name"
              {...field}
              error={!!formState.errors.lastName}
              autoComplete="none"
              variant="standard"
            />
          )}
        />
        {formState.touchedFields.lastName && formState.errors.lastName && (
          <div className="field-error">{formState.errors.lastName.message}</div>
        )}
      </ControllWrapper>

      <ControllWrapper className="mb">
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'Email field is required',
            pattern: {
              value: emailPattern,
              message: 'The email format is wrong, E.g: john@gmail.com',
            },
            validate: (value) => EmptyValidator(value, 'Email field is required'),
          }}
          render={({ field }) => (
            <TextField
              label="*Email"
              error={!!formState.errors.email}
              {...field}
              autoComplete="none"
              type="email"
              variant="standard"
            />
          )}
        />
        {formState.touchedFields.email && formState.errors.email && (
          <div className="field-error">{formState.errors.email.message}</div>
        )}
      </ControllWrapper>

      <ControllWrapper className="mb">
        <Controller
          name="confirmEmail"
          control={control}
          rules={{
            required: 'Email field is required',
            pattern: {
              value: emailPattern,
              message: 'The email format is wrong, E.g: john@gmail.com',
            },
            validate: {
              emptyValidation: (value) => EmptyValidator(value, 'Email field is required'),
              compareEmails: (value) =>
                value.trim() === getValues('email').trim() || 'Emails do not match',
            },
          }}
          render={({ field }) => (
            <TextField
              label="*Confirm email"
              error={!!formState.errors.confirmEmail}
              {...field}
              autoComplete="none"
              variant="standard"
            />
          )}
        />
        {formState.touchedFields.confirmEmail && formState.errors.confirmEmail && (
          <div className="field-error">{formState.errors.confirmEmail.message}</div>
        )}
      </ControllWrapper>

      <ColumnControlWrapper className="mb">
        <ControllWrapper>
          <Controller
            name="documentType"
            control={control}
            render={({ field }) => (
              <FormControl variant="standard">
                <InputLabel id="document">*Document</InputLabel>
                <Select
                  labelId="document"
                  variant="standard"
                  label="*Document"
                  autoWidth
                  defaultValue="ID"
                  {...field}
                >
                  <MenuItem value="ID">ID</MenuItem>
                  <MenuItem value="passport">Passport</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </ControllWrapper>

        <ControllWrapper>
          <Controller
            name="documentId"
            control={control}
            rules={{
              required: 'Document field is required',
              validate: (value) => EmptyValidator(value, 'Document field is required'),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!formState.errors.documentId}
                label="*Document number"
                autoComplete="none"
                variant="standard"
              />
            )}
          />
          {formState.touchedFields.documentId && formState.errors.documentId && (
            <div className="field-error">{formState.errors.documentId.message}</div>
          )}
        </ControllWrapper>
      </ColumnControlWrapper>

      <ControllWrapper className="mb">
        <Controller
          name="country"
          control={control}
          rules={{
            required: 'Country field is required',
          }}
          render={({ field }) => (
            <FormControl variant="standard">
              <Autocomplete
                id="country"
                {...field}
                onChange={(_, v) => onChangeCountry(v ?? '')}
                getOptionLabel={(option) => option || ''}
                isOptionEqualToValue={(option, value) => option === value}
                options={countries?.map((country) => country) ?? []}
                renderOption={(props, option) => (
                  <li {...props} key={option + Math.random()}>
                    {option}
                  </li>
                )}
                defaultValue=""
                ListboxProps={{ style: { maxHeight: 200 } }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label={countries?.length === 0 ? <CircularProgress size={24} /> : '*Country'}
                    inputProps={{ ...params.inputProps }}
                  />
                )}
              />
            </FormControl>
          )}
        />
        {formState.touchedFields.country && formState.errors.country && (
          <div className="field-error">{formState.errors.country.message}</div>
        )}
      </ControllWrapper>

      <ControllWrapper className="mb">
        <Controller
          name="city"
          control={control}
          rules={{
            required: 'City field is required',
          }}
          render={({ field }) => (
            <FormControl variant="standard">
              <Autocomplete
                id="city"
                {...field}
                defaultValue=""
                onChange={(_, v) => setValue('city', v as string)}
                getOptionLabel={(option) => option || ''}
                isOptionEqualToValue={(option, value) => option === value}
                options={citySelector.cities}
                disabled={citySelector.cities.length === 0 || citySelector.loading}
                ListboxProps={{ style: { maxHeight: 200 } }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label={citySelector.loading ? <CircularProgress size={24} /> : '*City'}
                    inputProps={{ ...params.inputProps }}
                  />
                )}
              />
            </FormControl>
          )}
        />
        {formState.touchedFields.city && formState.errors.city && (
          <div className="field-error">{formState.errors.city.message}</div>
        )}
      </ControllWrapper>

      <ControllWrapper className="mb">
        <Controller
          name="phone"
          control={control}
          rules={{
            required: 'Phone field is required',
            validate: (value) =>
              phoneValidator(value, {
                defaultCountry: currentCountryCode,
                message: 'Please enter a valid number',
              }),
          }}
          render={({ field }) => (
            <MuiTelInput
              {...field}
              placeholder="*Phone"
              autoComplete="none"
              label="*Phone"
              error={!!formState.errors.phone}
              defaultCountry={currentCountryCode}
              forceCallingCode
              variant="standard"
            />
          )}
        />
        {formState.touchedFields.phone && formState.errors.phone && (
          <div className="field-error">{formState.errors.phone.message}</div>
        )}
      </ControllWrapper>

      <div className="button-container">
        <Button
          disabled={!formState.isValid || emailValidationState.loading}
          className="full-width"
          buttonType="primary"
          type="submit"
        >
          {emailValidationState.loading ? <CircularProgress size="20px" /> : 'Next'}
        </Button>
      </div>
    </Form>
  );
};

export default FirstSection;
