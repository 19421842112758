import { matchIsValidTel } from 'mui-tel-input';
import type { MuiTelInputCountry } from 'mui-tel-input';

export type PhoneValidatorFunction = (
  phone: string,
  options: { message: string; defaultCountry: MuiTelInputCountry }
) => boolean | string;

export const phoneValidator: PhoneValidatorFunction = (phone, { message, defaultCountry }) =>
  matchIsValidTel(phone, { defaultCountry }) || message;
