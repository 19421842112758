import { createSlice } from '@reduxjs/toolkit';
import {
  IFirstSection,
  IFormFetchSection,
  IFormSection,
  IFormState,
} from 'utils/interfaces/form.interface';

import {
  saveFirstSectionAction,
  saveFormStateAction,
  saveLocationAction,
  saveOpenSeasonAction,
  saveSourceAction,
  thunkStoreCandidate,
  verifyCandidateFailureAction,
  verifyCandidateStartAction,
  verifyCandidateSuccessAction,
} from '../actions/form.action';

const firstSection: IFirstSection = {
  phone: '',
  firstName: '',
  lastName: '',
  email: '',
  candidateSource: '',
  documentId: {
    type: null,
    value: '',
  },
  location: {
    city: '',
    country: '',
    countryCode: '',
    longitude: 0,
    latitude: 0,
  },
};

const initialState: IFormSection &
  IFormState &
  IFormFetchSection & { verifyCandidate: IFormFetchSection; isOpenSeason: boolean } = {
  state: 0,
  firstSection,
  secondSection: {
    file: null,
    tp: null,
  },
  verifyCandidate: {
    error: '',
    hasError: false,
    loading: false,
  },
  loading: false,
  hasError: false,
  error: '',
  isOpenSeason: false,
};

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    value: initialState,
  },
  reducers: {
    saveFormState: saveFormStateAction,
    saveFirstSection: saveFirstSectionAction,
    saveLocation: saveLocationAction,
    saveSource: saveSourceAction,
    saveOpenSeason: saveOpenSeasonAction,
    verifyCandidateStart: verifyCandidateStartAction,
    verifyCandidateSuccess: verifyCandidateSuccessAction,
    verifyCandidateFailure: verifyCandidateFailureAction,
  },
  extraReducers: (builder) => {
    builder.addCase(thunkStoreCandidate.fulfilled, (state) => {
      state.value.loading = false;
      state.value.state = 2;
    });
    builder.addCase(thunkStoreCandidate.pending, (state) => {
      state.value.loading = true;
    });
    builder.addCase(thunkStoreCandidate.rejected, (state) => {
      state.value.loading = false;
      state.value.error =
        'Your information could not be saved, please contact us at traineeprogram@applaudostudios.com';
      state.value.hasError = true;
    });
  },
});

export const {
  saveFormState,
  saveFirstSection,
  saveLocation,
  saveSource,
  saveOpenSeason,
  verifyCandidateStart,
  verifyCandidateSuccess,
  verifyCandidateFailure,
} = formSlice.actions;

export default formSlice.reducer;
