import styled from 'styled-components';
import colors from 'styles/_colors';

export const SSImageSection = styled.div`
  & .image {
    margin: 160px 0 80px 32px;
  }

  @media (min-width: 300px) {
    display: none;
  }

  @media (min-width: 760) {
    display: none;
  }

  @media (min-width: 761px) {
    display: block;
  }
`;

export const SSContentSection = styled.div`
  margin: 160px 32px 80px 32px;

  align-self: center;
  justify-self: center;

  & .title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: ${colors.applaudoRed};
    margin-bottom: 30px;
  }

  & .subtitle-container {
    max-width: 600px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &__email {
      span {
        font-weight: 600;
      }
    }

    &__subtitle {
      margin-top: 16px;
      span {
        font-weight: 600;
      }
    }
  }

  @media (min-width: 300px) {
    grid-column: 1/-1;
    & .title {
      font-size: 36px;
    }
  }

  @media (min-width: 761px) {
    grid-column: 2;
    & .title {
      font-size: 56px;
    }
  }
`;
