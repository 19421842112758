import { useCallback, useEffect, useRef, useState } from 'react';
import { useSpring } from 'react-spring';

export const useFadeAnimation = (firstSection?: boolean) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [animFinished, setAnimFinished] = useState(false);
  const [activeAnimation, setActiveAnimation] = useState(false);
  const [top, setTop] = useState<number | null>(null);

  const style = useSpring({
    from: { y: 100, opacity: 0 },
    to: {
      y: firstSection ? 0 : !animFinished ? (activeAnimation ? 0 : 100) : 0,
      opacity: firstSection ? 1 : !animFinished ? (activeAnimation ? 1 : 0) : 1,
    },
    config: {
      duration: 300,
    },
    onRest: () => setAnimFinished(true),
  });

  const handleUserScroll = useCallback(() => {
    const { scrollY } = window;
    if (top && scrollY > top) {
      setActiveAnimation(true);
    } else setActiveAnimation(false);
  }, [top]);

  useEffect(() => {
    window.addEventListener('scroll', handleUserScroll);
    return () => {
      window.removeEventListener('scroll', handleUserScroll);
    };
  }, [handleUserScroll]);

  useEffect(() => {
    const { scrollY, innerHeight } = window;
    if (innerHeight >= 100 && top && scrollY > top) {
      setActiveAnimation(true);
    }
  }, [top]);

  useEffect(() => {
    if (
      elementRef.current &&
      elementRef.current.scrollWidth &&
      elementRef.current.getBoundingClientRect().top
    ) {
      const clientHeight =
        elementRef.current.clientHeight <= 900 ?
          elementRef.current.clientHeight - 250
          : elementRef.current.clientHeight - 1000;

      setTop(
        elementRef.current.getBoundingClientRect().top -
          elementRef.current.clientHeight -
          clientHeight
      );
    }
  }, []);

  return { style, elementRef };
};
