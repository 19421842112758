/* eslint-disable jsx-a11y/media-has-caption */
import { Rectangle } from 'components/blue-rectangle/Rectangle';
import React, { useRef, useState } from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import breakpoints from 'styles/_breakpoints';
import colors from 'styles/_colors';
import { useFadeAnimation } from 'utils/hooks/useFadeAnimation';

import foxNowSvg from '../../assets/images/meet-our-trainees/FoxNow.svg';
import lifeMilesSvg from '../../assets/images/meet-our-trainees/LifeMiles.svg';
import miamiHeatSvg from '../../assets/images/meet-our-trainees/MiamiHeat.svg';
import mullenLoweSvg from '../../assets/images/meet-our-trainees/MullenLowe.svg';
import rappiSvg from '../../assets/images/meet-our-trainees/Rappi.svg';
import wallmartSvg from '../../assets/images/meet-our-trainees/Walmart.svg';
import poster from '../../assets/images/meet-our-trainees/image 6.jpg';
import playSvg from '../../assets/images/meet-our-trainees/play.svg';
import video from '../../assets/videos/applaudo.mp4';
import {
  BrandsContainer,
  HeaderContainer,
  PlayButton,
  VideoContainer,
} from './components/meetOurTrainees.components';

const MeetOurTraineesWrapper = animated(styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  color: ${colors.white};
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;

  @media (min-width: ${breakpoints.tablet}) {
    gap: 70px;
  }
`);

const MeetOurTrainees = () => {
  const { style, elementRef } = useFadeAnimation();
  const [playing, setPlaying] = useState(false);
  const videoPlayer = useRef<HTMLVideoElement>(null);

  const playVideo = () => {
    if (videoPlayer.current) {
      videoPlayer.current.play();
    }
  };

  return (
    <Rectangle color={colors.applaudoNavyblue}>
      <MeetOurTraineesWrapper style={style} ref={elementRef}>
        <VideoContainer>
          <video
            src={video}
            poster={poster}
            controls={playing}
            onPlay={() => setPlaying(true)}
            ref={videoPlayer}
          />
          <PlayButton onClick={playVideo} playing={playing}>
            <img src={playSvg} alt="play-button" />
          </PlayButton>
        </VideoContainer>
        <HeaderContainer>
          <h3>Brands our trainees are working for</h3>
          <p>Our former trainees build solutions for leading brands in different industries.</p>
        </HeaderContainer>

        <BrandsContainer>
          <img className="miami-heat" src={miamiHeatSvg} alt="miami-heat-logo" />
          <img className="wallmart" src={wallmartSvg} alt="wallmart-logo" />
          <img className="rappi" src={rappiSvg} alt="rappi-logo" />
          <img className="fox-now" src={foxNowSvg} alt="fox-now-logo" />
          <img className="life-miles" src={lifeMilesSvg} alt="life-miles-logo" />
          <img className="mullen" src={mullenLoweSvg} alt="mullen-lowe-logo" />
        </BrandsContainer>
      </MeetOurTraineesWrapper>
    </Rectangle>
  );
};

export default MeetOurTrainees;
