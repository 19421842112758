import { createAsyncThunk } from '@reduxjs/toolkit';
import CountryService from 'api/services/CountryService';
import { AxiosResponse } from 'axios';
import { ICountryApiResponse } from 'utils/interfaces/country-api-response.interface';
import { ICountryAndCities } from 'utils/interfaces/country.interface';

import { GET_CITIES, GET_COUNTRIES } from '../actions-types/country-api.action-type';

export const thunkGetCountries = createAsyncThunk(GET_COUNTRIES, async () => {
  const countryService = new CountryService();
  const { data } = (await countryService.get()) as AxiosResponse<
    ICountryApiResponse<ICountryAndCities>
  >;
  if (data) return data.data;

  return null;
});

export const thunkGetCities = createAsyncThunk(GET_CITIES, (cities: string[]) => {
  if (cities.length > 0) {
    return cities;
  }
  return null;
});
