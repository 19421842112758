import styled from 'styled-components';
import breakpoints from 'styles/_breakpoints';

import colors from '../../../styles/_colors';

export const VideoContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;

  & video {
    object-fit: cover;
    width: 100%;
    height: 275px;

    @media (min-width: ${breakpoints.tablet}) {
      height: 766px;
    }
    @media (min-width: ${breakpoints.desktop}) {
      height: 752px;
    }
  }

  & .video-title-container {
    background-color: ${colors.white};
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
  }
`;

export const PlayButton = styled.button<{ playing: boolean }>`
  display: ${({ playing }) => (playing ? 'none' : 'block')};
  position: absolute;
  background-color: transparent;
  border: none;
  transition: 0.2s ease-in-out 0s;

  & :hover {
    transform: scale(1.05);
  }

  & img {
    object-fit: cover;
    cursor: pointer;
    height: 50px;
    width: 45px;

    @media (min-width: ${breakpoints.tablet}) {
      width: 138px;
      height: 138px;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;

  & h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: ${colors.applaudoRed};
    margin: 56px 0;

    @media (min-width: ${breakpoints.tablet}) {
      margin: 0 0 9px 0;
    }
    @media (min-width: ${breakpoints.desktop}) {
      font-size: 56px;
      line-height: 64px;
    }
  }

  & p {
    display: none;

    @media (min-width: ${breakpoints.tablet}) {
      display: block;
      margin: 0;
    }
  }
`;

export const BrandsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 0 30px 90px 30px;
  max-width: 1024px;

  & img {
    object-fit: cover;
  }

  & .miami-heat {
    width: 90px;
    height: 125px;

    @media (min-width: ${breakpoints.tablet}) {
      width: 82.59px;
      height: 82.59px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 92px;
      height: 92px;
    }
  }

  & .wallmart {
    width: 199.71px;
    height: 49.87px;
    @media (min-width: ${breakpoints.tablet}) {
      width: 82.59px;
      height: 82.59px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 92px;
      height: 92px;
    }
  }

  & .rappi {
    width: 100px;
    height: 80px;
    @media (min-width: ${breakpoints.tablet}) {
      width: 82.59px;
      height: 82.59px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 92px;
      height: 92px;
    }
  }

  & .fox-now {
    width: 145px;
    height: 35px;
    @media (min-width: ${breakpoints.tablet}) {
      width: 82.59px;
      height: 82.59px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 92px;
      height: 92px;
    }
  }

  & .life-miles {
    width: 134.2px;
    height: 60px;
    @media (min-width: ${breakpoints.tablet}) {
      width: 82.59px;
      height: 82.59px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 92px;
      height: 92px;
    }
  }

  & .mullen {
    width: 158px;
    height: 166px;
    @media (min-width: ${breakpoints.tablet}) {
      width: 82.59px;
      height: 82.59px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 92px;
      height: 92px;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    gap: 0;
    margin: 0 100px 90px 100px;
    padding: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 0 auto 90px auto;
    gap: 80px;
  }
`;
