import styled from 'styled-components';

export const Rectangle = styled.div<{ color: string }>`
  position: relative;
  background-color: ${(props) => props.color};

  &.form {
    width: 100%;
    height: 100%;
    padding: 64px;
    display: flex;
    flex-direction: column;
    background-color: #fff !important;
  }
`;
