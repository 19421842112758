import { AxiosError, AxiosResponse } from 'axios';
import { StrainnerErrorType } from 'utils/types/strainner-error.type';

export const handleAxiosError = (response: AxiosError | AxiosResponse) => {
  const error = response as AxiosError;
  if (error.isAxiosError) {
    return error.response?.data as StrainnerErrorType;
  }
  return false;
};
