import styled from 'styled-components';
import breakpoints from 'styles/_breakpoints';

import colors from '../../../styles/_colors';

type ProcessContainerProps = {
  isInReverse: boolean;
};

export const RecruitingProcessList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding: 0 30px;
  gap: 72px;
  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 208px;
    gap: 120px;
  }
`;
export const ProcessContainer = styled.div<ProcessContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  & > img {
    object-fit: cover;
    width: 139px;
    height: 96.5px;

    @media (min-width: ${breakpoints.tablet}) {
      width: 216px;
      height: 149.95px;
    }
    @media (min-width: ${breakpoints.desktop}) {
      width: 320px;
      height: 149.95px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > p {
      font-style: normal;
      font-weight: normal;
      line-height: 24px;
      margin: 0;
      @media (min-width: ${breakpoints.tablet}) {
        font-size: 16px;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: ${({ isInReverse }) => (isInReverse ? 'row-reverse' : 'row')};
  }
`;

export const ProcessTitle = styled.h4`
  color: ${colors.applaudoNavyblue};
  margin: 0 0 8px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  @media (min-width: ${breakpoints.desktop}) {
    font-size: 32px;
    line-height: 48px;
  } ;
`;
