import { Title } from 'components/benefits/components/beneffits.components';
import { Rectangle } from 'components/blue-rectangle/Rectangle';
import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';

import assesmentSvg from '../../assets/images/recruitment-process/assesment.svg';
import contactSvg from '../../assets/images/recruitment-process/contact.svg';
import interviewSvg from '../../assets/images/recruitment-process/interview.svg';
import technicalTestSvg from '../../assets/images/recruitment-process/technical-test.svg';
import colors from '../../styles/_colors';
import { useFadeAnimation } from '../../utils/hooks/useFadeAnimation';
import Process from './components/Process';
import { RecruitingProcessList } from './components/recruiting-process.components';

const RecruitingProcesWrapper = animated(styled.div`
  width: 100%;
  padding: 80px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
`);

const RecruitingProcess = () => {
  const { style, elementRef } = useFadeAnimation();

  return (
    <Rectangle color={colors.white}>
      <RecruitingProcesWrapper style={style} ref={elementRef}>
        <Title>Recruiting Process</Title>
        <RecruitingProcessList>
          <Process
            imgSrc={technicalTestSvg}
            title="Technical Test"
            isInReverse={false}
            description="After submitting your application, you will receive two technical tests to evaluate the fundamentals of programming. You will have 48 hours to complete it."
          />
          <Process
            imgSrc={contactSvg}
            title="Talent Acquisition Contact"
            isInReverse
            description="You will receive a call or email from the recruitment team to explain and follow up on your selection process. At the same time, any doubts you may have regarding the program or process can be clarified."
          />
          <Process
            imgSrc={assesmentSvg}
            title="Assessment Center"
            isInReverse={false}
            description="A virtual meeting will be held with a panel of judges and a group of applicants to the program with the aim of being able to get to know them better and carry out a case of conflict resolution (group activity). It is important to mention that a part of the Assessment will be carried out in English. At the end, feedback about your application will be provided."
          />
          <Process
            imgSrc={interviewSvg}
            title="Final Interview"
            isInReverse
            description="A final meeting will be held with a recruiter to have a more personal approach and evaluate competencies that go according to the program. In this phase, a series of questions will be asked about your professional or academic experience and your soft skills."
          />
        </RecruitingProcessList>
      </RecruitingProcesWrapper>
    </Rectangle>
  );
};
export default RecruitingProcess;
