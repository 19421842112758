import Button from 'components/button/Button';
import { DragAndDropContainer } from 'components/form-section/components/form-section.components';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { FileError } from './components/drag-and-drop.components';
import FilePreview from './components/file-preview';

type DragAndDropType = {
  setCandidateFile: (file: File | null) => void;
};

const DragAndDrop = ({ setCandidateFile }: DragAndDropType) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  const [draggin, setDraggin] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState(false);
  const dragCounter = useRef(0);

  const getPDFFileSize = (PDFFile: File) => (PDFFile.size / 1024 / 1024).toFixed(4);

  const validateFilesRestrictions = useCallback((PDFFile: File) => {
    const fileType = PDFFile.type.split('/').pop();
    const fileExtension = PDFFile.name.split('.').pop();
    return fileType === 'pdf' && fileExtension === 'pdf' && +getPDFFileSize(PDFFile) <= 25;
  }, []);

  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      dragCounter.current++;
      setDraggin(true);
    }
  };

  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current > 0) return;
    setDraggin(false);
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const draggFile = e.dataTransfer.files[0];
        if (!validateFilesRestrictions(draggFile)) {
          setFileError(true);
          e.dataTransfer.clearData();
          setDraggin(false);
          return;
        }
        setFile(draggFile);
        setFileError(false);
        setDraggin(false);

        e.dataTransfer.clearData();
        dragCounter.current = 0;
      }
    },
    [validateFilesRestrictions]
  );

  const handleOnChangeFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files?.length > 0) {
        const saveFile = e.target.files[0];
        if (!validateFilesRestrictions(saveFile)) {
          setFileError(true);
          return;
        }
        setFile(saveFile);
        setFileError(false);
      }
    },
    [validateFilesRestrictions]
  );

  useEffect(() => {
    const draggableDiv = dropRef.current;
    draggableDiv?.addEventListener('dragenter', handleDragIn);
    draggableDiv?.addEventListener('dragleave', handleDragOut);
    draggableDiv?.addEventListener('dragover', handleDragOver);
    draggableDiv?.addEventListener('drop', handleDrop);

    return () => {
      draggableDiv?.removeEventListener('dragenter', handleDragIn);
      draggableDiv?.removeEventListener('dragleave', handleDragOut);
      draggableDiv?.removeEventListener('dragover', handleDragOver);
      draggableDiv?.removeEventListener('drop', handleDrop);
    };
  }, [dropRef, handleDrop]);

  useEffect(() => {
    setCandidateFile(file);
  }, [file, setCandidateFile]);

  return (
    <>
      <DragAndDropContainer ref={dropRef} className={draggin ? 'ondrag' : ''}>
        <p className="title">
          Drag and drop your resume
          <span className="title__secondary">or</span>
        </p>
        <div className="rules">
          <Button
            onClick={() => fileInputRef.current?.click()}
            className="rules__select-file-btn"
            buttonType="primary"
          >
            Select your file
          </Button>
          <span>Only PDF files are allowed</span>
          <span>(Max size 25MB)</span>
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept="application/pdf"
          onChange={(e) => handleOnChangeFile(e)}
        />
      </DragAndDropContainer>
      {fileError && <FileError>Error</FileError>}
      {file && <FilePreview file={file} setFile={setFile} />}
    </>
  );
};

export default DragAndDrop;
