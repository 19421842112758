import SuccessClosedSVG from 'assets/images/success-closed-season.svg';
import { Rectangle } from 'components/blue-rectangle/Rectangle';
import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import colors from 'styles/_colors';
import { useFadeAnimation } from 'utils/hooks/useFadeAnimation';

import { CSCContentSection, CSImageSection, TPMailAnchor } from './closed-section.components';

const ClosedSectionWrapper = animated(
  styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 160px 0 80px 0;
    column-gap: 112px;
    row-gap: 32px;
    padding: 0 32px;
  `
);

const ClosedSection = () => {
  const { elementRef, style } = useFadeAnimation();
  return (
    <Rectangle color={colors.white} style={{ display: 'none' }}>
      <ClosedSectionWrapper ref={elementRef} style={style}>
        <CSImageSection>
          <div className="image">
            <img src={SuccessClosedSVG} alt="Closed Section" />
          </div>
        </CSImageSection>

        <CSCContentSection>
          <h1 className="title">Hey!</h1>
          <h3 className="subtitle">
            Unfortunately, at this moment, all of our Trainee Programs are full! 😄
          </h3>

          <div className="content-container">
            <p className="content-container_body">
              Follow us on social media to stay updated on when the programs will reopen and how you
              can join in the future. <br />
              <br /> If you have any questions, please email us at:
            </p>
            <TPMailAnchor href="mailto:traineeprogram@applaudostudios.com">
              traineeprogram@applaudostudios.com
            </TPMailAnchor>
            <p className="content-container__body">and we will assist you as soon as possible. </p>
          </div>
        </CSCContentSection>
      </ClosedSectionWrapper>
    </Rectangle>
  );
};

export default ClosedSection;
