import { AxiosError, AxiosResponse } from 'axios';
import { ICountryApiResponse } from 'utils/interfaces/country-api-response.interface';

import { ICountry, ICountryAndCities } from '../../utils/interfaces/country.interface';
import ApiService from '../abstract/ApiService';

class CountryService extends ApiService<ICountry, ICountryApiResponse<ICountryAndCities>> {
  constructor() {
    super(process.env.REACT_APP_COUNTRY_API as string, 'countries');
  }

  async getCities(
    country: string
  ): Promise<AxiosResponse<ICountryApiResponse<string>> | AxiosError> {
    try {
      return await this.axios.post('countries/cities', {
        country,
      });
    } catch (error) {
      return error as AxiosError;
    }
  }

  async getCountryCode(country: string): Promise<AxiosResponse<{ data: ICountry }> | AxiosError> {
    try {
      return await this.axios.post('countries/iso', {
        country,
      });
    } catch (error) {
      return error as AxiosError;
    }
  }
}

export default CountryService;
