import styled from 'styled-components';

import colors from '../../../styles/_colors';

export const TPProgramContent = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  @media (min-width: 300px) {
    padding-left: 0;
    flex-flow: column wrap;
  }

  @media (min-width: 951px) {
    padding-left: 200px;
    flex-flow: row nowrap;
    justify-content: center;
  }
`;

export const TPProgramSelectionWrapper = styled.div`
  display: flex;

  @media (min-width: 700px) {
    width: auto;
  }

  @media (min-width: 1141px) {
    width: 100%;
    max-width: 200px;
  }
`;

export const ProgramOptionsList = styled.ul`
  list-style: none;
  @media (min-width: 700px) {
    padding: 0;
    width: 100%;
  }
`;

export const ProgramOptions = styled.li`
  margin: 14px 0;
  line-height: 30px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;

  &:hover .reddiv {
    display: flex;
    align-self: flex-start;
    height: 2px;
    width: 100%;
    transform: scaleX(1.05);
    background-color: ${colors.applaudoRed};
    transition: width 0.4s ease 0s;
  }

  &.selected .reddiv {
    display: flex;
    align-self: flex-start;
    height: 2px;
    width: 100%;
    transform: scaleX(1.05);
    background-color: ${colors.applaudoRed};
    transition: width 0.4s ease 0s;
  }

  & .reddiv {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-start;
    height: 2px;
    width: 0px;
    background-color: ${colors.applaudoRed};
    transition: width 0.4s ease 0s;
  }
`;

export const ProgramsWrapper = styled.div`
  margin-top: 2rem;
  margin-left: 0;
  display: block;
  max-width: 848px;
  width: 100%;

  @media (min-width: 951px) {
    margin-top: 0;
    margin-left: 2rem;
  }
`;

export const Program = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0;
  justify-content: center;

  &.all {
    margin-bottom: 80px;
  }

  &.not-first {
    margin-top: 80px;
  }
`;

export const ProgramImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: 951px) {
    width: auto;
  }
`;

export const ProgramImage = styled.img`
  width: 279.88px;
  height: 222px;
`;

export const ProgramDescriptionContainer = styled.div`
  width: 100%;
  max-width: 336px;
  margin-top: 2rem;
  margin-left: 0;
  @media (min-width: 690px) {
    margin-top: 0;
    margin-left: 2rem;
  }
`;

export const ProgramDescriptionTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 1.5rem;
  color: ${colors.applaudoRed};
  @media (min-width: 500px) {
    font-size: 36px;
  }
`;

export const ProgramDescriptionBody = styled.div`
  max-width: 336px;
  & .qualifications {
    position: relative;
    &::before {
      position: absolute;
      margin: 0 12px 0 10px;
      border: 2px solid ${colors.applaudoYellow};
      border-radius: 4px;
      height: 16px;
      content: '';
    }
    & > p {
      margin-left: 22px;
    }
  }
  & .qs-list {
    padding-left: 26px;
  }
`;

export const ProgramDescriptionTechs = styled.div`
  margin: 1rem 0;
  max-width: 320px;

  & .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.coolGray};
  }

  & .techs-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    column-gap: 24px;
    margin: 1rem 0;
    row-gap: 16px;
  }

  & .techs-container .techs_container__tech {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    max-width: 57px;
    text-align: center;
    & > img {
      width: 32px;
      height: 32px;
      @media (min-width: 375px) {
        width: 56px;
        height: 56px;
      }
    }
    & > .tech-name {
      font-size: 14px;
      margin-top: 8px;
      @media (min-width: 375px) {
        font-size: 1rem;
      }
    }
  }
`;

export const ProgramDescriptionFooter = styled.div`
  display: flex;
  margin: 2rem 0;
  justify-content: center;
  justify-content: center;

  & > a {
    display: none;
    max-width: 147px;
    margin: 0;
  }

  @media (min-width: 500px) {
    justify-content: flex-start;
    justify-content: flex-start;
  }
`;
