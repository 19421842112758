import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';

import { ReactComponent as TPHat } from '../../assets/images/tp-hat.svg';
import TPImage from '../../assets/images/tp-image.png';
import colors from '../../styles/_colors';
import { useFadeAnimation } from '../../utils/hooks/useFadeAnimation';
import Button from '../button/Button';
import { Link } from '../red-section/components/red-section.components';
import {
  ContentDescription,
  ContentSection,
  ContentSubtitle,
  ContentTitle,
  ImageSection,
  P,
} from './components/trainee-program-description.components';

const TraineeProgramDescriptionWrapper = animated(styled.div`
  width: 100%;
  margin-top: 120px;
  position: relative;
  background-color: ${colors.applaudoGray};
  display: flex;
  height: auto;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: flex-start;

  @media (min-width: 650px) {
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
`);

const TraineeProgramDescription = () => {
  const { style, elementRef } = useFadeAnimation();
  return (
    <TraineeProgramDescriptionWrapper ref={elementRef} style={style}>
      <ImageSection imgSrc={TPImage} />
      <ContentSection>
        <ContentTitle>
          <TPHat className="tp-hat" />
          <div className="content-title__text">
            Trainee
            <span>Program</span>
          </div>
        </ContentTitle>
        <ContentSubtitle>Powered by Applaudo</ContentSubtitle>
        <ContentDescription>
          <P>
            Our Trainee Program is a<span> 3-month world-class </span>
            experience where trainees will have the opportunity to
            <span> remotely learn </span>
            and improve their skills in Software Development, UX/UI Design, or QA with the
            mentorship of our senior team during the whole program.
          </P>
          <P>
            You will have the opportunity to access this program from
            <span> any country throughout Latin America </span>
            and
            <span> receive remuneration for </span>
            the duration of the program.
          </P>
          <P style={{ display: 'none' }}>Ready for the challenge?</P>
          <Link href="#apply-now" style={{ display: 'none', margin: 0 }}>
            <Button buttonType="primary">Apply now</Button>
          </Link>
        </ContentDescription>
      </ContentSection>
    </TraineeProgramDescriptionWrapper>
  );
};

export default TraineeProgramDescription;
