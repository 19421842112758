import { createSlice } from '@reduxjs/toolkit';
import { thunkGetCountries } from 'store/actions/country-api.action';

type InitialState = {
  countries: string[];
  listOfCountries: { [k: string]: string[] };
  listOfDialCodes: { [k: string]: string };
};

const initialState: InitialState = {
  countries: [],
  listOfCountries: {},
  listOfDialCodes: {},
};

export const countrySlice = createSlice({
  name: 'form',
  initialState: {
    value: initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkGetCountries.fulfilled, (state, action) => {
      const countries: string[] = [];
      let listOfCountries: { [k: string]: string[] } = {};
      let listOfDialCodes: { [k: string]: string } = {};
      if (action.payload) {
        // eslint-disable-next-line no-restricted-syntax
        for (const data of action.payload) {
          countries.push(data.country);
          listOfCountries = {
            ...listOfCountries,
            [data.country]: data.cities,
          };
          listOfDialCodes = {
            ...listOfDialCodes,
            [data.country]: data.iso2,
          };
        }
      }
      if (countries && listOfCountries) {
        state.value.countries = countries;
        state.value.listOfCountries = listOfCountries;
        state.value.listOfDialCodes = listOfDialCodes;
      } else state.value = initialState;
    });
  },
});

export default countrySlice.reducer;
