import { Chip } from '@mui/material';
import styled, { css } from 'styled-components';
import colors from 'styles/_colors';

export type ClappChipVariants = 'default';

export type ClappChipType = {
  isActive?: boolean;
  chipVariant?: ClappChipVariants;
} & typeof defaultProps;

const defaultProps = Object.freeze<{ isActive?: boolean; chipVariant?: ClappChipVariants }>({
  isActive: false,
  chipVariant: 'default',
});

const chipVariants = (
  appColors: typeof colors,

  { chipVariant = 'default', isActive }: ClappChipType
) =>
  ({
    default: css`
      background-color: ${isActive ? appColors.applaudoNavyblue : colors.gainsboro};
      color: ${isActive ? appColors.white : colors.applaudoNavyblue};
      font-weight: ${isActive ? 600 : 400};

      &:hover {
        background-color: ${isActive ? appColors.applaudoNavyblue : appColors.survaGray};
      }

      &:active {
        background-color: ${isActive ? appColors.applaudoNavyblue : appColors.quickSilver};
      }
    `,
  }[chipVariant]);

const ClappChip = styled(Chip)<ClappChipType>`
  && {
    border-radius: 5px;
    display: flex;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 8px;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;

    ${(props) => chipVariants(colors, props)}
  }
`;

ClappChip.defaultProps = defaultProps;
export default ClappChip;
